import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOptions, deleteOptions } from "..//./../redux/actions/productOptionA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";
import Submit from "../components/Submit";

const DeleteOptions = () => {
    const prodopt = useSelector((state) => state.productOptionR);
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        product_id: prod.product.id,
        ids: []
    });
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (prod.product.id) {
            dispatch(getOptions(prod.product.id));
        }
    }, [dispatch, prod.product.id]);

    const handleChange = (event) => {
        let element = event.target;
        let value = element.value;
        let checked = element.checked;
        if (checked) {
            setData({
                ...data,
                ids: {
                    ...data.ids,
                    [value]: value,
                }
            });
            setTotal(total+1);
        } else {
            delete data.ids[value];
            setTotal(total-1);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(deleteOptions(data))
    };

    return (
        <div className="w-11/12 m-auto">
            <h1 className="h1">Delete Options</h1>
            {prodopt.loading ? (
                <Loading />
            ) : prodopt.row_options.length === 0 ? (
                <div className="mt-10">
                    <NoRecords message="No Options available." />
                </div>
            ) : (
                <Fragment>
                    <table className="table my-10">
                        <thead>
                            <tr>
                                <th>...</th>
                                <th>Display Name</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(prodopt.row_options).map(
                                ([key, item]) => (
                                    <tr key={key}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                value={item.id}
                                                className="w-5 h-5 cursor-pointer"
                                                id={`option${item.id}`}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <label
                                                className="block cursor-pointer"
                                                htmlFor={`option${item.id}`}
                                            >
                                                {item.display_name}
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                className="block cursor-pointer"
                                                htmlFor={`option${item.id}`}
                                            >
                                                {item.name}
                                            </label>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="flex justify-center"
                    >
                        {total > 0 && (
                            <Submit
                                status={prodopt.button}
                                text="Delete selected"
                                icon="fa fa-trash"
                            />
                        )}
                    </form>
                </Fragment>
            )}
        </div>
    );
};
export default DeleteOptions;
