import React, { useEffect, useState, Fragment } from "react";
import { getTaxonomiesSelected } from "../../redux/actions/taxonomyA";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { createOptions } from "../../redux/actions/productOptionA";
import Submit from "../components/Submit";
import Error from "../components/Error";
import {Redirect, useParams } from "react-router-dom";

const DefineOptions = ({ selected, resetOption, product }) => {
    const taxo = useSelector((state) => state.taxonomyR);
    const prodopt = useSelector((state) => state.productOptionR);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        ids: {},
        selected: {},
        product_id: product,
    });
    let { key } = useParams();

    useEffect(() => {
        dispatch(getTaxonomiesSelected({ name: selected }));
        dispatch({ type: 'RESTART_STEPS' })
    }, [dispatch, selected]);

    const handleChange = (event) => {
        let element = event.target;
        let value = element.value;
        let checked = element.checked;
        let id = element.id;
        let taxo_id = element.getAttribute("data-taxo-id");
        if (checked === true) {
            setData({
                ...data,
                ids: selected,
                selected: {
                    ...data.selected,
                    [id]: {
                        taxo_id: taxo_id,
                        taxo_value: value,
                    },
                },
            });
        } else {
            delete data.selected[id];
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createOptions(data));
        // console.log(data)
    };

    return (
        <Fragment>
            {/* eslint-disable-next-line */}
            {prodopt.step == 3 && <Redirect to={`/products/${key}/edit/active-options`} />}
            {prodopt.step === 0 && (
                <div className="animate__animated animate__fadeIn animate__faster mt-10  w-11/12 m-auto">
                    {taxo.taxoLoad ? (
                        <Loading />
                    ) : (
                        <div>
                            <div className="w-full lg:w-8/12">
                                <Error
                                    status={prodopt.error}
                                    errors={prodopt.errors}
                                    close="PRODUCT_OPTION_ERROR_OFF"
                                />
                            </div>
                            {Object.entries(taxo.taxoSelected).map(
                                ([key, item]) => (
                                    <div
                                        key={key}
                                        className="my-2 border border-gray-400 bg-gray-100 p-5 w-full lg:w-8/12"
                                    >
                                        <h3 className="text-gray-700 font-bold border-b border-gray-300 mb-3">
                                            {item.name}
                                        </h3>
                                        <div className="grid grid-cols-5 gap-y-8">
                                            {Object.entries(
                                                item.taxonomy_values
                                            ).map(([key_2, item_2]) => (
                                                <div
                                                    key={`${key}_${key_2}`}
                                                    className="flex justify-start items-center cursor-pointer"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={`id${item.id}_${item_2.id}`}
                                                        className="w-5 h-5 mr-2  cursor-pointer"
                                                        onChange={handleChange}
                                                        value={item_2.id}
                                                        data-taxo-id={item.id}
                                                    />
                                                    <label
                                                        className="cursor-pointer"
                                                        htmlFor={`id${item.id}_${item_2.id}`}
                                                    >
                                                        {item_2.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                            <div className="flex justify-center mt-10  w-full lg:w-8/12">
                                <button
                                    type="button"
                                    onClick={resetOption}
                                    className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded mr-2"
                                >
                                    <i className="fa fa-sync"></i> Reset
                                </button>
                                {Object.entries(data.selected).length > 0 && (
                                    <form action="" onSubmit={handleSubmit}>
                                        <Submit
                                            icon="fa fa-arrow-right"
                                            text="Next"
                                            status={prodopt.button}
                                        />
                                    </form>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default DefineOptions;
