import React, { Fragment } from "react";
import ReactDOM from "react-dom";

const ModalZ = (props) => {
    return ReactDOM.createPortal(
        <Fragment>
            {props.status && 
            <div
                className="fixed top-0 left-0 h-screen w-full animate__animated animate__fadeIn animate__faster"
                style={{ backgroundColor: "rgba(0,0,0,.5)" }}
            >
                <div className="absolute top-0 h-full w-full flex justify-center pt-64 items-start">
                    <div className="w-11/12 lg:w-2/5 h-auto bg-white rounded shadow-lg">
                        <div className="flex justify-between p-2 bg-blue-100">
                            <span className="font-bold">{props.title}</span>
                            <span>
                                <i className="fa fa-times fa-lg cursor-pointer" onClick={props.close}></i>
                            </span>
                        </div>
                        <div className="pt-0 pb-10 px-10">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>}
        </Fragment>,
        document.getElementById("modal")
    );
};

export default ModalZ;
