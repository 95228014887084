import React from "react";
import Modal from "../components/ModalVerify";
import { useDispatch, useSelector } from "react-redux";
import TrialPayment from "./TrialPayment";
import { getPaymentPlans } from "../../redux/actions/paymentActions";
import OtherPaymentMethods from "./OtherPaymentMethods";

// const TrialExpired = ({ status, currentPlan, otherPaymentMethods }) => {
//     if (status === 0) {
//         return (
//             <TrialPayment
//                 currentPlan={currentPlan}
//                 otherPaymentMethods={otherPaymentMethods}
//             />
//         );
//     }
// };

const MakePayment = () => {
    const payment = useSelector((state) => state.paymentReducers);
    const dispatch = useDispatch();
    const [methodPayment, setMethodPayment] = React.useState(false);

    React.useEffect(() => {
        dispatch(getPaymentPlans());
    }, [dispatch]);

    const handleMethodPayment = () => {
        setMethodPayment(!methodPayment);
        console.log(methodPayment);
    };

    return (
        <Modal title={payment.verify.message}>
            {methodPayment ? (
                <OtherPaymentMethods
                    currentPlan={payment.verify.payment_plan_id}
                    otherPaymentMethods={handleMethodPayment}
                />
            ) : (
                <React.Fragment>
                    {payment.verify.status === 0 && (
                        <TrialPayment
                            currentPlan={payment.verify.payment_plan_id}
                            otherPaymentMethods={handleMethodPayment}
                        />
                    )}
                </React.Fragment>
            )}
        </Modal>
    );
};

export default MakePayment;
