const initialState = {
    info: {},
    modal_verify_loading: false,
    verify_finish: false,
    verify: {
        status: 0,
        message: 'Verify Payment',
        type: 'trial'
    },
    history: {},
    payment_plan: {},
    payment_plan_exist: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'GET_INFO': {
            return {
                ...state,
                info: action.payload,
            }
        }
        case 'VERIFY': {
            return {
                ...state,
                verify: action.payload,
                verify_finish: true,
            }
        }
        case 'PAYMENT_COMPLETED': {
            window.location.href = "/home";
            break;
        }
        case 'GET_HISTORY': {
            return {
                ...state,
                history: action.payload
            }
        }
        case 'CANCEL_SUBSCRIPTION': {
            window.location.href = "/subscription";
            break;
        }
        case 'GET_PAYMENT_PLANS': {
            return {
                ...state,
                payment_plan: action.payload,
                payment_plan_exist: true
            }
        }
        default: return state;
    }
}