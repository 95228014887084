import React, { Fragment, useEffect, useState } from "react";
// import largeLogo from "../../assets/img/nuethicLarge.png";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../redux/actions/supportA";
import Error from "../components/Error";
import Success from "../components/Success";
import Breadcrumbs from '../components/Breadcrumbs'

const Support = () => {
    const [data, setData] = useState({
        name: "",
        store: "",
        question: "",
        email: "",
    });
    const support = useSelector((state) => state.supportReducers);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(getData(data));
    };

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        if (support.success.status) {
            setData({
                name: "",
                store: "",
                question: "",
                email: "",
            });
            setTimeout(() => {
                dispatch({ type: "SUPPORT_SUCCESS_OFF" });
            }, 3000);
        }
    }, [dispatch, support.success.status]);

    return (
        <Fragment>
            <Breadcrumbs text="Support & Contact" />
            {/* <div className="flex justify-center w-full mb-10">
                <img src={largeLogo} alt="Nuethic Logo" className="w-3/5" />
            </div> */}
            <div className="flex flex-col items-center mb-10">
                <h1 className="supportPageHeader">CONTACT US</h1>
                <div className="supportH3 text-center">
                    Please feel Free to send us a message to let us know how we
                    can help.
                    <br></br>We typically respond within 24 hours.
                </div>
            </div>
            <div className="flex w-11/12 mx-auto text-center lg:text-left lg:flex-row flex-col lg:ml-24">
                <div className="w-full lg:w-1/2">
                    <div className="mb-5 border-b border-gray-200 pb-5">
                        <h2 className="supportH2">Nuethic Sales</h2>
                        <h3 className="supportH3">
                            <ul>960 N 400 E Suite C.</ul>
                            <ul>North Salt Lake, UT 84054</ul>
                            <ul>
                                <span className="font-bold">Tel: </span>
                                <a className="link" href="tel:+18019946857">
                                    +1-801-994-6857
                                </a>
                            </ul>
                            <ul>
                                <span className="font-bold">Email: </span>
                                <a
                                    className="link"
                                    href="mailto:sales@nuethic.com"
                                >
                                    Sales@nuethic.com
                                </a>
                            </ul>
                        </h3>
                    </div>

                    <div className="mb-5 border-b border-gray-200 pb-5">
                        <h2 className="supportH2">Nuethic General Inqueries</h2>
                        <h3 className="supportH3">
                            <ul>960 N 400 E Suite C.</ul>
                            <ul>North Salt Lake, UT 84054</ul>
                            <ul>
                                <span className="font-bold">Tel: </span>
                                <a className="link" href="tel:+18019946857">
                                    +1-801-994-6857
                                </a>
                            </ul>
                            <ul>
                                <span className="font-bold">Email: </span>
                                <a
                                    className="link"
                                    href="mailto:contact@nuethic.com"
                                >
                                    Contact@nuethic.com
                                </a>
                            </ul>
                        </h3>
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="contactContainer">
                        <h1 className="w-full block text-center text-xl font-bold mb-3">
                            Contact us
                        </h1>
                        <div>
                            <Error
                                status={support.error.status}
                                errors={support.error.message}
                                close="SUPPORT_ERROR_OFF"
                            />
                            <Success
                                status={support.success.status}
                                message={support.success.message}
                                close="SUPPORT_SUCCESS_OFF"
                            />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <div>Name</div>
                                <input
                                    className="Input"
                                    type="text"
                                    onChange={handleChange}
                                    name="name"
                                    value={data.name}
                                    placeholder="Your Name.."
                                ></input>
                            </div>
                            <div className="mb-3">
                                <div>Email</div>
                                <input
                                    className="Input"
                                    type="text"
                                    onChange={handleChange}
                                    name="email"
                                    value={data.email}
                                    placeholder="Your email.."
                                ></input>
                            </div>
                            <div className="mb-3">
                                <div>Store Name</div>
                                <input
                                    className="Input"
                                    type="text"
                                    onChange={handleChange}
                                    name="store"
                                    placeholder="Store Name..."
                                    value={data.store}
                                ></input>
                            </div>
                            <div className="mb-3">
                                <div>Question</div>
                                <textarea
                                    className="Input"
                                    rows="10"
                                    onChange={handleChange}
                                    name="question"
                                    placeholder="Question"
                                    value={data.question}
                                ></textarea>
                            </div>
                            <div className="flex justify-center mt-5">
                                {support.button ? (
                                    <button
                                        className="bg-white border-b-4  py-2 px-10 border border-blue-900 text-black rounded opacity-75"
                                        disabled
                                    >
                                        <i className="fa fa-spin fa-spinner"></i>{" "}
                                        Sending..
                                    </button>
                                ) : (
                                    <button className="bg-white border-b-4 hover:bg-gray-100 hover:shadow py-2 px-10 border border-blue-900 text-black rounded">
                                        <i className="fa fa-envelope"></i>{" "}
                                        Submit
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Support;
