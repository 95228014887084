import api from '../../api';

export const userData = () => async dispatch => {
    dispatch({type:'USER_LOAD'})
    try {
        const response = await api.get(`profile`);
        if(response.status===200) {
            //console.log(response.data)
            dispatch({
                type: 'USER_GET',
                payload: response.data
            })
        }
    } catch (error) {
        dispatch({type:'USER_LOAD_OFF'})
        console.log(error)
    }
}

export const updateProfile = (data) => async dispatch => {
    dispatch({type:'USER_BTN'})
    try {
        const response = await api.put(`user/${data.id}`, data)
        if(response.status === 200) {
            dispatch({
                type: 'USER_UPDATE',
                payload: data
            })
        }
    } catch (error) {
        console.log(error.response.data.errors)
        dispatch({
            type: 'USER_ERROR',
            payload: error.response.data.errors
        })
    }
    
}