import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { loginOutAction } from '../../redux/actions/loginA'

const Logout = () => {

    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(loginOutAction())
    },[dispatch]);

    return (
        <div>
            <i className="fa fa-spin fa-spinner"></i>
        </div>
    )
 }

 export default Logout;