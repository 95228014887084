import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { verifyLogin } from './functions'
// Layouts
import Content from "./views/layouts/Content";
import LoginContent from "./views/layouts/LoginContent";
// dashboard
import DashBoard from "./views/home/DashBoard";
// User
import Profile from "./views/users/Profile";
// Login
import Login from "./views/login/Login";
import HomeLogin from "./views/login/HomeLogin";
import Logout from "./views/login/Logout";
// Star
// import Init from "./views/Init";
// Taxonomies
import TaxonomyList from "./views/taxonomies/TaxonomyList";
import TaxoCreate from "./views/taxonomies/TaxoCreate";
import TaxoEdit from "./views/taxonomies/TaxoEdit";
import TaxoDelete from "./views/taxonomies/TaxoDelete";
// Categories
import CategoriesList from "./views/categories/CategoryList";
import CatCreate from "./views/categories/CatCreate";
import CatEdit from "./views/categories/CatEdit";
import CatDelete from "./views/categories/CatDelete";
// Products
import ProductList from "./views/products/ProductList";
import ProductCreate from "./views/products/ProductCreate";
import ProductDelete from "./views/products/ProductDelete";
import ProductScreen from "./views/products/ProductScreen";
// Other Pages
import Support from "./views/support/Index";
import SubscriptionInit from "./views/subscription/SubscriptionInit"
// Payments
import MakePayment from './views/subscription/MakePayment'
import { useDispatch } from "react-redux";
import { loginfromBC } from './redux/actions/loginA'
const contentLayouts = [
    "/home",
    "/profile",
    "/support",
    "/taxonomies",
    "/categories",
    "/products",
    "/subscription",
    "/payments",
    "/logout"
];

const loginlayouts = ["/login", "/hlogin"];

const FirstLoad = () => {
    return (
        <Fragment>
            {verifyLogin() && <Redirect to="/home" />}
            {!verifyLogin() && <Redirect to="/login" />}
        </Fragment>
    )
}

const App = () => {

    let params = new URLSearchParams(document.location.search.substring(1));
    let user = params.get("user");
    const dispatch = useDispatch();

    useEffect( () => {
        if(user!=null) {
            dispatch(loginfromBC({user: user}))
        }
    },[dispatch, user])

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={FirstLoad} />
                <Route path={contentLayouts}>
                    <Content>
                        <Switch>
                            <Route exact path="/home" component={DashBoard} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/subscription" component={SubscriptionInit} />
                            
                            {/* taxonomies  ********************************************/}
                            <Route
                                exact
                                path="/taxonomies"
                                component={TaxonomyList}
                            />
                            <Route
                                exact
                                path="/taxonomies/create"
                                component={TaxoCreate}
                            />
                            <Route
                                exact
                                path="/taxonomies/:taxoid/edit"
                                component={TaxoEdit}
                            />
                            <Route
                                exact
                                path="/taxonomies/:taxoid/delete"
                                component={TaxoDelete}
                            />
                            {/* categories  ********************************************/}
                            <Route
                                exact
                                path="/categories"
                                component={CategoriesList}
                            />
                            <Route
                                exact
                                path="/categories/create"
                                component={CatCreate}
                            />
                            <Route
                                exact
                                path="/categories/:catkey/edit"
                                component={CatEdit}
                            />
                            <Route
                                exact
                                path="/categories/:catkey/delete"
                                component={CatDelete}
                            />
                            {/* products  ********************************************/}
                            <Route
                                exact
                                path="/products"
                                component={ProductList}
                            />
                            <Route
                                exact
                                path="/products/create"
                                component={ProductCreate}
                            />
                            <Route
                                exact
                                path="/products/:key/delete"
                                component={ProductDelete}
                            />
                            {/* Productos intern switchs ******************************/}
                            <Route
                                exact
                                path="/products/:key/edit"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/edit"
                                component={ProductScreen}
                            />
                            
                            <Route
                                exact
                                path="/products/:key/edit/variations"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/options"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/delete-options"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/delete"
                                component={ProductDelete}
                            />
                            <Route
                                exact
                                path="/subscription"
                                component={SubscriptionInit}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/complex-rules"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/active-options"
                                component={ProductScreen}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/active-rules"
                                component={ProductScreen}
                            />
                            {/* <Route
                                exact
                                path="/products/:key/edit/mix-outfit"
                                component={ProductScreen}
                            /> */}
                            <Route exact path="/payments" component={MakePayment} />
                            <Route exact path="/logout" component={Logout} />
                        </Switch>
                    </Content>
                </Route>
                <Route path={loginlayouts}>
                    <LoginContent>
                        <Switch>
                            <Route exact path="/hlogin" component={HomeLogin} />
                            <Route exact path="/login" component={Login} />
                        </Switch>
                    </LoginContent>
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default App;
