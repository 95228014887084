const initialState = {
    button: false,
    loading: false,
    error: false,
    errors: [],
    row_options: [],
    success: false,
    step: 0,
    variants: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'PRODUCT_OPTION_BUTTON': {
            return {
                ...state,
                button: true
            }
        }
        case 'PRODUCT_OPTION_BUTTON_OFF': {
            return {
                ...state,
                button: false
            }
        }
        case 'PRODUCT_OPTION_LOADING': {
            return {
                ...state,
                loading: true
            }
        }
        case 'PRODUCT_OPTION_LOADING_OFF': {
            return {
                ...state,
                loading: false
            }
        }
        case 'PRODUCT_OPTION_ERROR': {
            return {
                ...state,
                error: true,
                errors: action.payload,
                button: false
            }
        }
        case 'PRODUCT_OPTION_ERROR_OFF': {
            return {
                ...state,
                error: false
            }
        }
        case 'CREATE_OPTIONS': {
            return {
                ...state,
            }
        }
        case 'PRODUCT_OPTION_SUCCESS': {
            return {
                ...state,
                error: false,
                step: 3,
                button: false,
            }
        }
        case 'PRODUCT_OPTIONS': {
            return {
                ...state,
                loading: false,
                row_options: action.payload.data,
            }
        }
        case 'PRODUCT_OPTION_DELETE': {
            return {
                ...state,
                button: false,
                row_options: [],
                total: 0,
            }
        }
        case 'RESTART_STEPS': {
            return {
                ...state,
                step: 0,
                error: false,
                button: false,
                success: false
            }
        }
        case 'PRODUCT_OPTIONS_GET_VARIANTS': {
            return {
                ...state,
                variants: action.payload,
                loading: false
            }
        }
        default: return state;
    }
}