import React from 'react';

const DashBoard = () => {
    return (
        <div>
            <h1 className="dashboardHero col-span-3">Welcome to the NuEthic Attribute Builder</h1>
            
        </div>

    )
}

export default DashBoard;