import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomies, TaxoDeleteAction } from "../../redux/actions/taxonomyA";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import CancelBtn from "../components/CancelBtn";
import Loading from "../components/Loading";
import Alert from "../components/Alert";
import { Redirect } from "react-router-dom";

const TaxoDelete = (props) => {
    const taxoKey = props.match.params.taxoid;
    const taxos = useSelector((state) => state.taxonomyR);
    const [data, setData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (!taxos.taxoGet) {
            dispatch(getTaxonomies());
        }
    }, [dispatch, taxos.taxoGet]);

    useEffect(() => {
        if (taxos.taxonomies.length > 0) {
            setData(taxos.taxonomies[taxoKey]);
        }
    }, [dispatch, taxos.taxonomies, taxoKey]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(TaxoDeleteAction(data));
    };

    return (
        <div className="">
            {taxos.taxoSuccess && <Redirect to="/taxonomies" />}
            <Breadcrumbs text="Taxonomies / Delete" />
            <h1 className="h1 lg:w-8/12 mt-10">Delete options</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Alert
                    title="Alert"
                    message="This action cannot be reverted."
                />
                <Error
                    status={taxos.taxoErrStatus}
                    errors={taxos.taxoErrors}
                    close="TAXO_ERR_CLOSE"
                />
                <Success
                    status={taxos.taxoSuccess}
                    message="Operation completed successfully."
                    close="TAXO_SUC_CLOSE"
                />
            </div>
            {!data.id ? (
                <Loading />
            ) : (
                <Fragment>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="form lg:w-4/12 animate__animated animate__fadeIn animate__faster"
                        autoComplete="off"
                    >
                        <div className="flex flex-col">
                            <label htmlFor="" className="mr-2 w-full label">
                                Name:
                            </label>
                            <input
                                type="text"
                                className="input w-full opacity-50"
                                value={data.name}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <label htmlFor="" className="mr-2 w-full label">
                                Display Name:
                            </label>
                            <input
                                type="text"
                                className="input w-full opacity-50"
                                value={data.display_name}
                                disabled
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <label htmlFor="" className="mr-2 w-full label">
                                Type:
                            </label>
                            <select
                                disabled
                                className="select opacity-50"
                                value={data.type}
                            >
                                <option value="radio_buttons">
                                    Radio Buttons
                                </option>
                                <option value="swatch">Swatch</option>
                                <option value="rectangles">Rectangles</option>
                                <option value="dropdown">Dropdown</option>
                            </select>
                        </div>
                        <div className="flex justify-center mt-5">
                            <CancelBtn
                                text="Cancel"
                                icon="fa fa-arrow-left"
                                url="/taxonomies"
                            />
                            <Submit
                                status={taxos.taxoBtn}
                                text="Yes, Delete"
                                icon="fa fa-trash"
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

export default TaxoDelete;
