import React from 'react';
import { Link } from 'react-router-dom';

const AddBtn = ( {text,icon,url} ) => {
    return (
        <Link to={url} className="bg-gray-300 mx-1 hover:bg-gray-400 text-gray-900 font-bold py-2 px-4 rounded my-5">
            <i className={icon}></i> {text}
        </Link>
    )
}

export default AddBtn;