import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logo.png'

const MenuLogin = ({options}) => {
    const [isOpen, setIsOpen] = useState(false)
    const menuStatus = !isOpen ? 'hidden' : '';

    return (
        <div className="animate__animated animate__fadeIn animate__faster menuStyle lg:flex py-3 md:py-0 sticky">
            <div className="flex justify-between w-full items-center  lg:w-2/12">
                <div className="text-white">
                    <img src={Logo} className="w-40" alt="Logo NuEthic" />
                </div>
                <i className="lg:hidden fa fa-bars text-white mr-3 fa-2x cursor-pointer" onClick={ () => setIsOpen(!isOpen)}></i>
            </div>
            <ul className={`${menuStatus} lg:block w-full lg:w-10/12 lg:flex lg:justify-end animate__animated animate__fadeIn animate__faster`}>
                {Object.entries(options).map(([key, item]) => (
                    <li key={key}>
                        <Link to={item.url} className="text-center menuStyle lg:text-left text-white block p-3 md:border-r hover:bg-gray-900 hover:outline-none rounded-lg">
                            <i className={`${item.icon} mr-2`}></i>
                            {item.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MenuLogin;
