import api from "../../api";

export const getProducts = () => async (dispatch) => {
    dispatch({ type: "PRODUCT_LOAD" });
    try {
        const response = await api.get("product");
        if (response.status === 200) {
            dispatch({
                type: "PRODUCT_GET_ALL",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const productStore = (data) => async (dispatch) => {
    dispatch({ type: "PRODUCT_BTN" });
    try {
        const response = await api.post("product", data);
        dispatch({
            type: "PRODUCT_ADD",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "PRODUCT_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const productUpdate = (data) => async (dispatch) => {
    dispatch({ type: "PRODUCT_BTN" });
    try {
        const response = await api.put(`product/${data.id}`, data);
        dispatch({
            type: "PRODUCT_EDIT",
            payload: response.data.data
        });
    } catch (error) {
        dispatch({
            type: "PRODUCT_ERR",
            payload: error.response.data.errors,
        });
    }
};


export const productDelete = (data) => async (dispatch) => {
    dispatch({ type: "PRODUCT_BTN" });
    try {
        await api.delete(`product/${data}`);
        dispatch({
            type: "PRODUCT_REMOVE",
            payload: data
        });
    } catch (error) {
        dispatch({
            type: "PRODUCT_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const getAllFromServer = (bc_product_id) => async dispatch => {
    dispatch( { type: "PRODUCT_LOAD" } );
    try {
        const response = await api.get(`/product/${bc_product_id}/server`)
        if(response.status === 200) {
            dispatch({
                type: "PRODUCT_FROM_SERVER",
                payload: response.data.data
            })
        }
    } catch (error) {
        console.log(error);
    }
}

export const getAllFromBC = () => async dispatch => {
    dispatch({ type: 'PRODUCT_SERVER_LOADING' })
    try {
        const response = await api.get(`/product/get/all/from/server`);
        console.log(response)
        dispatch({
            type: 'PRODUCT_SERVER_ALL',
            payload: response.data.data
        })
    } catch (error) {
        console.log(error);
    }
}

export const addFromSerever = (data) => async dispatch => {
    dispatch({ type: 'PRODUCT_SERVER_LOADING' })
    try {
        const response = await api.post(`/product/add/from/server`, data);
        console.log(response)
        dispatch({
            type: 'PRODUCT_ADDED_FROM_SERVER'
        })
    } catch (error) {
        dispatch({
            type: 'PRODUCT_ADD_FROM_SERVER_ERROR',
            payload: error.response.data.errors
        })
    }
}

export const removeFromApp = (data) => async dispatch => {
    dispatch({ type: "PRODUCT_BTN" });
    try {
        await api.post('/product/remove/from/app', data);
        dispatch({
            type: "PRODUCT_REMOVE_SUCCESS"
        })
    } catch (error) {
        console.log(error)
    }
}   