import api from "../../api";

export const createComplexRules = (data) => async (dispatch) => {
    dispatch({ type: "COMPLEX_RULES_BUTTON" });
    try {
        const response = await api.post("/complex-rules", data);
        console.log(response);
        if (response.data.failed_image) {
            dispatch({
                type: "COMPLEX_ERROR",
                payload: response.data.image.errors,
            });
        } else if (response.data.failed_image) {
            dispatch({
                type: "COMPLEX_ERROR",
                payload: response.data.price.original.errors,
            });
        } else {
            dispatch({
                type: "SAVE_COMPLEX_RULES",
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const getRules = (product_id) => async (dispatch) => {
    dispatch({ type: "COMPLEX_RULES_LOADING" });
    try {
        const response = await api.get(
            `/complex-rules/${product_id}/get-rules`
        );
        if (response.status === 200) {
            dispatch({
                type: "COMPLEX_RULES_GET_RULES",
                payload: response.data.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const deleteAllEntries = (product_id) => async (dispatch) => {
    dispatch({ type: "COMPLEX_RULES_LOADING" });
    try {
        await api.delete(
            `/complex-rules/${product_id}/delete-all`
        );
        dispatch({ type: "COMPLEX_RULES_DELETE_ENTRIES" });
    } catch (error) {
        console.log(error);
    }
};
