import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData, getDataDetails } from "../../redux/actions/RMactions";
import Loading from "../components/Loading";
import ModalZ from "../components/ModalZ";
import Modal from "../components/Modal";
import { getHistory } from "../../redux/actions/paymentActions";
import Breadcrumbs from "../components/Breadcrumbs";

const SubsDetails = ({ status, data, close }) => {
    const EI = useSelector((state) => state.RMreducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDataDetails(data));
    }, [dispatch, data]);

    // console.log("Data:", data);
    // console.log("EI:", EI);
    return (
        <ModalZ title="License Details" status={status} close={close}>
            {EI.loading_details ? (
                <Loading />
            ) : (
                <div key={EI.id}>
                    <ul className="w-11/12 m-auto pt-5">
                        <li className="border-b border-gray-200 p-2">
                            <span className="font-bold">License:</span>{" "}
                            <span className="break-all">{EI.result.license}</span>
                        </li>
                        <li className="border-b border-gray-200 p-2">
                            <span className="font-bold">Date Purchased:</span>{" "}
                            {EI.result.created_at}
                        </li>
                        <li className="border-b border-gray-200 p-2">
                            <span className="font-bold">Expiration Date:</span>{" "}
                            {EI.result.expiration_date}
                        </li>
                        <li className="border-b border-gray-200 p-2">
                            <span className="font-bold">Description:</span>{" "}
                            {EI.results[0].payment_plan.description}
                        </li>
                    </ul>
                </div>
            )}
        </ModalZ>
    );
};

const PaymentHistory = ({ status, close }) => {
    const payments = useSelector((state) => state.paymentReducers);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHistory());
    }, [dispatch]);

    return (
        <Modal status={status} close={close} title="Payments History">
            {payments.history.length > 0 ? (
                <ul className="w-11/12 m-auto mt-5">
                    {Object.values(payments.history).map((item) =>
                        item.error_message ? (
                            <li className="border-b border-gray-200 p-1">
                                <i className="fa fa-times text-red-400 mr-2"></i>
                                {item.created_at} {item.error_message}{" "}
                                {item.ssl_card_number}
                            </li>
                        ) : (
                            <li className="border-b border-gray-200 p-1">
                                <i className="fa fa-check text-green-400 mr-2"></i>
                                {item.created_at} {item.ssl_card_number}{" "}
                                {item.ssl_result_message}
                            </li>
                        )
                    )}
                </ul>
            ) : (
                <div
                    class="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mt-5"
                    role="alert"
                >
                    <p class="text-sm">No history.</p>
                </div>
            )}
        </Modal>
    );
};

const SubscriptionInit = () => {
    const RM = useSelector((state) => state.RMreducer);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getData());
    }, [dispatch]);
    const [isOpen, setisOpen] = useState(false);
    const [data, setData] = useState(0);
    const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
    const handleClick = (event) => {
        var keyVal = event.target.getAttribute("data-key");
        setData(keyVal);
        setisOpen(true);
    };

    //console.log(RM);

    return (
        <Fragment>
            <Breadcrumbs text="Subscription & Payments" />

            <div className="w-11/12 m-auto">
                {RM.loading ? (
                    <Loading />
                ) : RM.results.length > 0 ? (
                    <div>
                        <br></br>
                        <div className="subscriptionM">Current license</div>
                        <span className="subscription">Current License:</span>
                        <span> {RM.results[0].license}</span>
                        <div>
                            <span className="subscription">
                                Current Level Subscription:
                            </span>
                            <span> {RM.results[0].payment_plan.name}</span>
                        </div>
                        <div>
                            <span className="subscription">Payment Plan: </span>
                            <span>
                                {RM.results[0].payment_plan.price} /{" "}
                                {RM.results[0].payment_plan.billing_cycle}
                            </span>
                        </div>
                        <div className="flex justify-end my-3">
                            <button
                                className="bg-black text-white p-2 rounded hover:bg-gray-900"
                                onClick={() => setOpenPaymentHistory(true)}
                            >
                                <i className="fa fa-history"></i> Payment
                                History
                            </button>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>License</th>
                                    <th>expiration date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(RM.results).map(
                                    ([key, data]) => (
                                        <tr key={data.id}>
                                            <td>{data.license}</td>
                                            <td>{data.expiration_date}</td>
                                            <td>
                                                <button
                                                    className="Sbutton"
                                                    onClick={handleClick}
                                                    data-key={data.id}
                                                >
                                                    Details
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>There are no results</div>
                )}
                {data !== 0 && (
                    <SubsDetails
                        status={isOpen}
                        data={data}
                        close={() => setisOpen(false)}
                    />
                )}
                <PaymentHistory
                    status={openPaymentHistory}
                    close={() => setOpenPaymentHistory(false)}
                />
            </div>
        </Fragment>
    );
};

export default SubscriptionInit;
