import axios from 'axios';

import { apiURL } from './functions'

axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": 'Bearer '+localStorage.getItem('access_token')
}

export default axios.create({
    baseURL: apiURL
})