import { combineReducers } from 'redux';
import mainReducers from './mainReducers'
import loginR from './loginR'
import userR from './userR'
import taxonomyR from './taxonomyR'
import taxonomyValueR from './taxonomyValueR'
import categoryR from './categoryR'
import productR from './productR'
import variationR from './variationR'
import productOptionR from './productOptionR'
import RMreducer from "./RMreducer"
import complexRulesR from './complexRulesR'
import mixOutfitBuilderR from './mixOutfitBuilderR'
import licenseReducers from './licenseReducers'
import paymentReducers from './paymentReducers'
import supportReducers from './supportReducers'

export default combineReducers({
    mainReducers,
    loginR,
    userR,
    taxonomyR,
    taxonomyValueR,
    categoryR,
    productR,
    variationR,
    productOptionR,
    RMreducer,
    complexRulesR,
    mixOutfitBuilderR,
    licenseReducers,
    paymentReducers,
    supportReducers
})