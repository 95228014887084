import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVariants } from "../../redux/actions/productOptionA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";

const ActiveOptions = () => {
    const options = useSelector((state) => state.productOptionR);
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prod.product.id) {
            dispatch(getVariants(prod.product.id));
        }
    }, [dispatch, prod.product.id]);

    // console.log(options);
    return (
        <div className="w-11/12 m-auto">
            <h1 className="h1">Active Options</h1>
            <h2 className="text-lg underline font-bold mt-5">
                {prod.product.name}
            </h2>
            {options.loading && <Loading />}
            {!options.loading && (
                <div className="animate__animated animate__fadeIn animate__faster">
                    {options.variants.length === 0 && !options.loading && (
                        <NoRecords message="No variants / sku's availables" />
                    )}
                    {options.variants.length > 0 && (
                        <table className="table mt-5">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>SKU</th>
                                    <th>Price</th>
                                    <th>Option Values</th>
                                    <th>Cost</th>
                                    <th>...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(options.variants).map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.sku}</td>
                                        <td>${item.cost_price}</td>
                                        <td>
                                            {Object.values(
                                                item.option_values
                                            ).map((value) => (
                                            <span key={item.id+value.id}>[{value.option_display_name} - {value.label}] </span>
                                            ))}
                                        </td>
                                        <td>${item.price}</td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActiveOptions;
