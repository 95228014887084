const initialState = {
    button: false,
    error: {
        status: false,
        message: []
    },
    success: {
        status: false,
        message: ""
    },
}

export default ( state = initialState, action) => {
    switch(action.type) {
        case 'SUPPORT_BUTTON': {
            return {
                ...state,
                button: true
            }
        }
        case 'SUPPORT_ERROR': {
            return {
                ...state,
                button: false,
                error: {
                    status: true,
                    message: action.payload
                },
                success: {
                    status: false,
                    message: ""
                }
            }
        }
        case 'SUPPORT_SUCCESS': {
            return {
                ...state,
                button: false,
                error: {
                    status: false,
                    message: []
                },
                success: {
                    status: true,
                    message: action.payload
                }
            }
        }
        case 'SUPPORT_ERROR_OFF': {
            return {
                ...state,
                error: {
                    status: false,
                    message: []
                },
            }
        }
        case 'SUPPORT_SUCCESS_OFF': {
            return {
                ...state,
                success: {
                    status: false,
                    message: ""
                }
            }
        }
        default: return state;
    }
}