import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productDelete,getProducts, getAllFromServer } from "../../redux/actions/productA";
import Submit from "../components/Submit";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import { Redirect } from 'react-router-dom'
import Breadcrumbs from "../components/Breadcrumbs";

const InputField = ({ text, name, change, value }) => {
    return (
        <div className="flex flex-col w-full px-5">
            <label htmlFor="" className="mr-2 w-full label">
                {text}
            </label>
            <input
                disabled
                type="text"
                className="input w-full"
                name={name}
                onChange={change}
                value={value}
            />
        </div>
    );
};

const ProductDelete = (props) => {
    const index_key = props.match.params.key;
const prod = useSelector((state) => state.productR);
    const emptyValues = {
        name: "",
        type: "physical",
        weight: "",
        sku: "",
        id: "",
    };
    const [data, setData] = useState(emptyValues);
    const dispatch = useDispatch();
    const totalproduct = Object.entries(prod.product).length;
    const totalProd = Object.entries(prod.products).length;

    useEffect(() => {
        if (!prod.productGet) {
            dispatch(getProducts());
        }
    }, [dispatch, prod.productGet]);

    useEffect(() => {
        if (totalProd > 0) {
            if(totalproduct > 0) {
                if (prod.products[index_key].bc_product_id !== prod.product.id) {
                    dispatch(getAllFromServer(prod.products[index_key].bc_product_id));
                }
            } else {
                dispatch(getAllFromServer(prod.products[index_key].bc_product_id));
            }
        }
    }, [dispatch, prod.products, index_key, totalProd, totalproduct, prod.product.id]);

    useEffect(() => {
        dispatch({ type: "PRODUCT_RESET_ALERTS" });
    }, [dispatch]);

    useEffect(() => {
        if (totalproduct > 0) {
            setData({
                ...prod.product
            });
        }
    }, [prod.product, prod.products, totalproduct]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(data)
        dispatch(productDelete(data.id));
    };
    console.log()
    return (
        <div className="">
            <Breadcrumbs text="Products / Delete" />
            {prod.productSuccess && <Redirect to="/products" />}
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Alert title="Alert" message="This action cannot be reverted." />
            </div>
            {prod.productLoad ? (
                <Loading />
            ) : (
                <Fragment>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="form lg:w-1/3 animate__animated animate__fadeIn animate__faster"
                        autoComplete="off"
                    >
                        <div className="flex">
                            <InputField
                                text="Name"
                                name="name"
                                value={data.name}
                            />
                        </div>

                        <div className="flex mt-3">
                            <InputField
                                text="Sku"
                                name="sku"
                                value={data.sku}
                            />
                        </div>

                        <div className="flex justify-center mt-5">
                            <Submit
                                status={prod.productBtn}
                                text="Yes, Delete"
                                icon="fa fa-trash"
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

export default ProductDelete;
