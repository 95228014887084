import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { getOptions } from "../../redux/actions/productOptionA";
import Submit from "../components/Submit";
import { createComplexRules } from "../../redux/actions/complexRulesA";
import api from "../../api";
import NoRecords from "../components/NoRecords";
import Error from '../components/Error';
import { Redirect, useParams } from 'react-router-dom'

const Inputs = ({ options, status, type, handleChange, reset }) => {
    return (
        <Fragment>
            {status && (
                <div className="my-10">
                    {Object.entries(options).map(([key, value]) => (
                        <div
                            className="flex items-center jusstify-start mb-2"
                            key={key}
                        >
                            <label
                                className="label w-3/12 text-right mr-5"
                                htmlFor=""
                            >
                                {value.label}:
                            </label>
                            <input
                                name={`field${key}`}
                                option-value-id={value.id}
                                onChange={handleChange}
                                className={`w-9/12 input ${reset}`}
                                type={type}
                            />
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    );
};

const Element = ({ text, handleSelect, options }) => {
    return (
        <div className=" flex items-center">
            <label htmlFor="" className="w-4/12 text-right mr-5">
                {text}
            </label>
            <select onChange={handleSelect} className="select w-8/12">
                <option value=""></option>
                {Object.entries(options).map(([key, value]) => (
                    <option key={key} value={`${value.id}-${key}`}>
                        {value.display_name}
                    </option>
                ))}
            </select>
        </div>
    );
};

const ComplexRules = ({ product_id }) => {
    let { key } = useParams();
    const prod = useSelector((state) => state.productR);
    const prodopt = useSelector((state) => state.productOptionR);
    const rules = useSelector( state => state.complexRulesR)
    const dispatch = useDispatch();

    // image
    const [openImage, setOpenImage] = useState(false);
    const [ovImage, setOvImage] = useState(0);
    const [dataImage, setDataImage] = useState({});

    // price
    const [ovPrice, setOvPrice] = useState(0);
    const [openPrice, setOpenPrice] = useState(false);
    const [dataPrice, setDataPrice] = useState({});

    useEffect(() => {
        if (prod.product.id) {
            dispatch(getOptions(prod.product.id));
        }
    }, [dispatch, prod.product.id]);

    const handleSelectImage = (event) => {
        var values = event.target.value;
        var split_value = values.split("-");
        setOvImage(split_value[1]);
        setOpenImage(true);
        setDataImage({
            option_id: split_value[0],
        });
    };

    const handleSelectPrice = (event) => {
        var values = event.target.value;
        var split_value = values.split("-");
        resetValues("price");
        setOvPrice(split_value[1]);
        setOpenPrice(true);
        setDataPrice({
            option_id: split_value[0],
        });
    };

    const resetValues = (value) => {
        var elements = document.getElementsByClassName(value);
        let x;
        for (x = 0; x < elements.length; x++) {
            document.getElementsByClassName(value)[0].value = "";
        }
    };

    const uploadImages = async (event) => {
        var formData = new FormData();
        var imagedata = event.target.files[0];
        var element = event.target;
        var optionValue = element.getAttribute("option-value-id");
        var name = element.name;
        formData.append(name, imagedata);
        formData.append("field", name);
        const response = await api.post(
            "/complex-rules/upload-image",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        let file_name = response.data;
        // console.log(response.data);
        setDataImage({
            ...dataImage,
            images: {
                ...dataImage.images,
                [name]: {
                    filename: file_name,
                    option_value: optionValue,
                },
            },
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(
            createComplexRules({
                dataImage,
                dataPrice,
                product_id: prod.product.id,
            })
        );
    };

    const handleChangePrice = (event) => {
        var element = event.target;
        var optionValue = element.getAttribute("option-value-id");
        var valor = element.value;
        setDataPrice({
            ...dataPrice,
            elements: {
                ...dataPrice.elements,
                [event.target.name]: {
                    option_value: optionValue,
                    value: valor,
                },
            },
        });
    };

    return (
        <Fragment>
            {rules.success && <Redirect to={`/products/${key}/edit/active-rules`} />}
            <div className="w-11/12 m-auto animate__animated animate__fadeIn animate__faster">
                <h1 className="h1 w-full m-auto">Complex Rules</h1>
                <h2 className="underline text-lg font-bold mt-5">
                    {prod.product.name}
                </h2>
            </div>
            {prod.productLoad && prodopt.row_options.length === 0 ? (
                <Loading />
            ) : (
                prodopt.row_options.length > 0 && (
                    <div className="w-11/12 m-auto animate__animated animate__fadeIn animate__faster">
                        <Error status={rules.error} errors={rules.errors} close="COMPLEX_ERROR_OFF" />
                        <div className="mt-10 flex flex-col justify-center items-center">
                            <div className="w-full lg:w-7/12 py-2 border-b border-b-gray-100">
                                <Element
                                    text="Change Image by"
                                    options={prodopt.row_options}
                                    handleSelect={handleSelectImage}
                                />
                                <Inputs
                                    options={
                                        prodopt.row_options[ovImage]
                                            .option_values
                                    }
                                    status={openImage}
                                    type="file"
                                    handleChange={uploadImages}
                                    reset="image"
                                />
                            </div>
                            <div className="w-full lg:w-7/12 py-2 border-b border-b-gray-100">
                                <Element
                                    text="Change Price by"
                                    options={prodopt.row_options}
                                    handleSelect={handleSelectPrice}
                                />
                                <Inputs
                                    options={
                                        prodopt.row_options[ovPrice]
                                            .option_values
                                    }
                                    reset="price"
                                    status={openPrice}
                                    type="number"
                                    handleChange={handleChangePrice}
                                />
                            </div>
                        </div>
                        <form
                            action=""
                            onSubmit={handleSubmit}
                            className="flex justify-center w-full mt-5"
                        >
                            <Submit
                                status={rules.button}
                                text="Save Changes"
                                icon="fa fa-save"
                            />
                        </form>
                    </div>
                )
            )}

            {!prod.productLoad && !prodopt.loading && prodopt.row_options.length === 0 && (
                <div className="w-11/12 m-auto animate__animated animate__fadeIn animate__faster mt-10"> 
                    <NoRecords message="There is not options created." />
                </div>
            )}
        </Fragment>
    );
};

export default ComplexRules;
