import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productUpdate  } from "../../redux/actions/productA";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import Loading from "../components/Loading";
import api from '../../api'

const InputField = ({ text, name, change, value }) => {
    return (
        <div className="flex flex-col w-1/2 px-5">
            <label htmlFor="" className="mr-2 w-full label">
                {text}
            </label>
            <input
                type="text"
                className="input w-full"
                name={name}
                onChange={change}
                value={value}
            />
        </div>
    );
};

const ProductEdit = (props) => {
    // const index_key = props.match.params.key;
    const prod = useSelector((state) => state.productR);
    const emptyValues = {
        name: "",
        description: "",
        type: "physical",
        weight: "",
        sku: "",
        price: "",
        category_id: "",
        page_title: "",
        meta_keywords: "",
        search_keywords: "",
        meta_description: "",
    };
    const [data, setData] = useState(emptyValues);
    const dispatch = useDispatch();
    const cate = useSelector((state) => state.categoryR);
    const totalproduct = Object.entries(prod.product).length;

    useEffect(() => {
        if (totalproduct > 0) {
            // console.log(prod.product)
            var meta_keywords = prod.product.meta_keywords.toString();
            setData({
                ...prod.product,
                category_id: prod.product.categories[0],
                meta_keywords: meta_keywords,
            });
        }
    }, [prod.product, prod.products, totalproduct]);

    useEffect( () => {
        dispatch({type:'PRODUCT_RESET_ALERTS'})
    },[dispatch]);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const uploadImages = async (event) => {
        var formData = new FormData();
        var imagedata = event.target.files[0];
        formData.append('image_file', imagedata);
        const response = await api.post(
            "/product/upload-image",
        formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        let file_name = response.data;
        // console.log(response.data);
        setData({
            ...data,
            image_file: file_name
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(data);
        dispatch(productUpdate(data));
    };

    return (
        <div className="">
            <h1 className="h1 lg:w-8/12">Edit Product</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Error
                    status={prod.productErrStatus}
                    errors={prod.productErrors}
                    close="PRODUCT_ERR_CLOSE"
                />
                <Success
                    status={prod.productSuccess}
                    message="Operation completed successfully."
                    close="PRODUCT_SUC_CLOSE"
                />
            </div>
            {prod.productLoad ? (
                <Loading />
            ) : (
                <Fragment>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="form lg:w-9/12 animate__animated animate__fadeIn animate__faster"
                        autoComplete="off"
                    >
                        <div className="flex">
                            <InputField
                                text="Name"
                                name="name"
                                change={handleChange}
                                value={data.name}
                            />
                            <InputField
                                text="Description"
                                name="description"
                                change={handleChange}
                                value={data.description}
                            />
                        </div>

                        <div className="flex mt-3">
                            <div className="flex flex-col w-1/2 px-5">
                                <label htmlFor="" className="mr-2 w-full label">
                                    Type:
                                </label>
                                <select
                                    name="type"
                                    id="type"
                                    className="select"
                                    onChange={handleChange}
                                    value={data.type}
                                >
                                    <option value="physical">Physical</option>
                                    <option value="digital">Digital</option>
                                </select>
                            </div>
                            <InputField
                                text="Weight"
                                name="weight"
                                change={handleChange}
                                value={data.weight}
                            />
                        </div>

                        <div className="flex mt-3">
                            <InputField
                                text="Sku"
                                name="sku"
                                change={handleChange}
                                value={data.sku}
                            />
                            <InputField
                                text="Price"
                                name="price"
                                change={handleChange}
                                value={data.price}
                            />
                        </div>

                        <div className="flex mt-3">
                            <div className="flex flex-col w-1/2 px-5">
                                <label htmlFor="" className="mr-2 w-full label">
                                    Category:
                                </label>
                                <select
                                    name="category_id"
                                    id="category_id"
                                    className="select"
                                    onChange={handleChange}
                                    value={data.category_id}
                                >
                                    <option value=""></option>
                                    {Object.entries(cate.categories).map(
                                        ([key, item]) => (
                                            <option
                                                key={key}
                                                value={`${item.id}`}
                                            >
                                                {item.name}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div className="flex flex-col w-1/2 px-5">
                                <label htmlFor="" className="mr-2 w-full label">
                                    Image:
                                </label>
                                <input
                                    type="file"
                                    className="input w-full"
                                    name="image_file"
                                    id="image_file"
                                    onChange={uploadImages}
                                />
                            </div>
                            {/* <InputField
                                text="Page Title"
                                name="page_title"
                                change={handleChange}
                                value={data.page_title}
                            /> */}
                        </div>

                        {/* <div className="flex mt-3">
                            
                            <InputField
                                text="Meta Description"
                                name="meta_description"
                                change={handleChange}
                                value={data.meta_description}
                            />
                        </div>

                        <div className="flex mt-3">
                            <InputField
                                text="Search Keywords"
                                name="search_keywords"
                                change={handleChange}
                                value={data.search_keywords}
                            />
                            <InputField
                                text="Meta Keywords:"
                                name="meta_keywords"
                                change={handleChange}
                                value={data.meta_keywords}
                            />
                        </div> */}

                        <div className="flex justify-center mt-5">
                            <Submit
                                status={prod.productBtn}
                                text="Save changes"
                                icon="fa fa-save"
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

export default ProductEdit;
