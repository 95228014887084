import React from "react";
import Button from "../components/Submit";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "../../redux/actions/paymentActions";
import Error from "../components/Error";
import PayCards from "../../assets/img/cards.jpg";
import axios from "axios";
import Loading from "../components/Loading";

const FormInput = ({
    name,
    label,
    id,
    type,
    handleChange,
    placeholder,
    value,
}) => {
    const formType = type || "text";
    const formName = label || name;
    const formValue = value || "";
    return (
        <div className="grid mb-3">
            <div className="label-control w-full mb-0">
                <label htmlFor={id} className="label text-left capitalize">
                    {formName}
                </label>
            </div>
            <div className="w-full">
                <input
                    id={id}
                    type={formType}
                    className="input w-full"
                    name={name}
                    onChange={handleChange}
                    placeholder={placeholder}
                    value={formValue}
                />
            </div>
        </div>
    );
};

const TrialPayment = ({ currentPlan, otherPaymentMethods }) => {
    const dispatch = useDispatch();
    const main = useSelector((state) => state.mainReducers);
    const payment = useSelector((state) => state.paymentReducers);

    const [data, setData] = React.useState({
        card_number: "",
        expiration_date: "",
        cvv: "",
        real_address: "",
        locality: "",
        administrative_area_level_1: "",
        postal_code: "",
        country: "",
        payment_plan_id: currentPlan === 4 ? 1 : currentPlan,
        firstname: "",
        lastname: "",
    });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(data);
        dispatch(createPayment(data));
    };

    const findAddressShipping = async (event) => {
        event.persist();
        setData({
            ...data,
            real_address: event.target.value,
        });
        // console.log(event)
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${event.target.value}&key=AIzaSyAc2XEMUHNVwd_q8vlfXSoj5GYCAXecN4k`,
                {
                    headers: null,
                }
            );
            // console.log(response);
            if (response.data.results.length > 0) {
                setData({
                    ...data,
                    address: response.data.results[0],
                    exists_address: true,
                    address_list: response.data.results,
                    real_address: event.target.value,
                });
            } else {
                setData({
                    ...data,
                    exists_address: false,
                    real_address: event.target.value,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validAddress = (event) => {
        for (var i = 0; i < data.address.address_components.length; i++) {
            var addressType = data.address.address_components[i].types[0];
            var arreglo = {
                ...arreglo,
                [addressType]: data.address.address_components[i]["short_name"],
            };
        }
        setData({
            ...data,
            ...arreglo,
            exists_address: false,
            real_address: event.target.getAttribute("data-value"),
        });
    };

    return (
        <React.Fragment>
            <div className="w-10/12 m-auto">
                <Error
                    status={main.statusError}
                    errors={main.errors}
                    size="w-11/12"
                    close="CLOSE_ERROR"
                />
            </div>
            {!payment.payment_plan_exist ? (
                <Loading />
            ) : (
                <form
                    action=""
                    className="form animate__animated animate__fadeIn animate__faster"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <fieldset className="fieldset  w-full">
                        <legend className="legend">Make a Payment</legend>

                        <div className="grid mb-3">
                            <div className="label-control w-full">
                                <label
                                    htmlFor="payment_plan_id"
                                    className="label capitalize text-left"
                                >
                                    Payment Plan
                                </label>
                            </div>
                            <div className="w-full">
                                <select
                                    className="select w-full"
                                    id="payment_plan_id"
                                    name="payment_plan_id"
                                    onChange={handleChange}
                                    value={data.payment_plan_id}
                                >
                                    {Object.entries(payment.payment_plan).map(
                                        ([key, value]) => (
                                            <option value={value.id} key={key}>
                                                {value.name} -{" "}
                                                {value.description} - $
                                                {value.price}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>

                        <FormInput
                            name="card_number"
                            label="Credit Card"
                            id="card_number"
                            type="text"
                            handleChange={handleChange}
                            placeholder="XXXXXXXXXXXXXXXX"
                            value={data.card_number}
                        />
                        <div className="relative">
                            <div className="grid mb-3">
                                <div className="label-control w-full mb-0">
                                    <label
                                        htmlFor="real_address"
                                        className="label text-left capitalize"
                                    >
                                        Address
                                    </label>
                                </div>
                                <div className="input-control w-full">
                                    <input
                                        id="real_address"
                                        name="real_address"
                                        value={data.real_address}
                                        type="text"
                                        className="input w-full"
                                        onChange={findAddressShipping}
                                        placeholder="1223 SW 23st"
                                    />
                                </div>
                            </div>
                            {data.exists_address && (
                                <ul
                                    id="addresses"
                                    className="w-full bg-blue-100 shadow border absolute"
                                >
                                    {Object.entries(data.address_list).map(
                                        ([key, value]) => (
                                            <li
                                                key={key}
                                                className="border absolute w-full bg-yellow-100 hover:bg-yellow-200 p-3 cursor-pointer z-10"
                                                data-value={
                                                    value.formatted_address
                                                }
                                                onClick={validAddress}
                                            >
                                                <i className="fa fa-map-marker-alt"></i>{" "}
                                                {value.formatted_address}
                                            </li>
                                        )
                                    )}
                                </ul>
                            )}
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                            <FormInput
                                name="firstname"
                                label="Firtsname"
                                id="first_name"
                                type="text"
                                handleChange={handleChange}
                                placeholder=""
                                value={data.firstname}
                            />
                            <FormInput
                                label="Lastname"
                                name="lastname"
                                type="text"
                                id="lastname"
                                handleChange={handleChange}
                                placeholder=""
                                value={data.lastname}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                            <FormInput
                                name="expiration_date"
                                label="Exp Date"
                                id="expiration_date"
                                type="number"
                                handleChange={handleChange}
                                placeholder="MMYY"
                                value={data.expiration_date}
                            />
                            <FormInput
                                label="CVV"
                                name="cvv"
                                type="number"
                                id="cvv"
                                handleChange={handleChange}
                                placeholder="XXX"
                                value={data.cvv}
                            />
                        </div>
                        {/* <div className="form-group">
                        <div className="label-control">
                            <label
                                htmlFor="amount"
                                className="label capitalize"
                            >
                                Amount $:
                            </label>
                        </div>
                        <div className="input-control">
                            <input
                                id="amount"
                                type="number"
                                value={data.amount}
                                className="input"
                                name="amount"
                                disabled
                            />
                        </div>
                    </div> */}
                        <div className="flex items-center mt-5">
                            <div className="w-full md:6/12">
                                <img
                                    src={PayCards}
                                    alt={PayCards}
                                    className=""
                                />
                                <button
                                    type="button"
                                    className="appearance-none hover:bg-green-200 p-1 px-3 bg-green-300 border border-green-400 mt-3 rounded text-green-700"
                                    onClick={otherPaymentMethods}
                                >
                                    Pay less with ACH{" "}
                                    <i className="fa fa-arrow-right"></i>
                                </button>
                            </div>
                            <div className="w-1/12"></div>
                            <div className="w-full md:5/12 text-right">
                                <Button
                                    status={main.statusBtn}
                                    text="Subscribe"
                                    icon="certificate"
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
            )}
        </React.Fragment>
    );
};

export default TrialPayment;
