import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import ProductEdit from "./ProductEdit";
import Breadcrumbs from "../components/Breadcrumbs";
import VariationMain from "../variations/VariationMain";
import { useDispatch, useSelector } from 'react-redux'
import ProductOptions from '../productOptions/ProductOptions'
import DeleteOptions from '../productOptions/DeleteOptions'
import ComplexRules from '../productOptions/ComplexRules'
// import MixOutfitBuilder from '../productOptions/MixOutfitBuilder'
import {
        getProducts,
        getAllFromServer
    } from "../../redux/actions/productA";
import { getCategories } from "../../redux/actions/categoryA";
import ActiveOptions from '../productOptions/ActiveOptions'
import ActiveRules from '../productOptions/ActiveRules'

const LinksProduct = ( { url, text, icon} ) => {
    
    return (
        <li className="border-b border-gray-300">
            <Link
                to={url}
                className="block p-3 hover:bg-blue-100"
            >
                <i className={icon}></i> {text}
                <i className="fa fa-angle-right float-right pt-1"></i>
            </Link>
        </li>
    );
};

const ProductScreen = (props) => {

    const index_key = props.match.params.key;
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();
    const cate = useSelector((state) => state.categoryR);
    const totalProd = Object.entries(prod.products).length;
    const totalproduct = Object.entries(prod.product).length;

    useEffect(() => {
        if (!cate.catGet) {
            dispatch(getCategories());
        }
    }, [dispatch, cate.catGet]);

    useEffect(() => {
        if (!prod.productGet) {
            dispatch(getProducts());
        }
    }, [dispatch, prod.productGet]);

    useEffect(() => {
        if (totalProd > 0) {
            if(totalproduct > 0) {
                if (prod.products[index_key].bc_product_id !== prod.product.id) {
                    dispatch(getAllFromServer(prod.products[index_key].bc_product_id));
                }
            } else {
                dispatch(getAllFromServer(prod.products[index_key].bc_product_id));
            }
        }
    }, [dispatch, prod.products, index_key, totalProd, totalproduct, prod.product.id]);
    

    // const handleOptions = () => {
    //     dispatch(createOptions(160))
    // }

    return (
        <BrowserRouter>
            <Fragment>
                <Breadcrumbs text="Products / Modify" />
                <div className="flex w-full flex-col lg:flex-row">
                    <ul className="w-full lg:w-3/12 mt-10 ">
                        <LinksProduct url={`/products/${props.match.params.key}/edit`} text="Product" icon="fa fa-box" />
                        <LinksProduct url={`/products/${props.match.params.key}/edit/options`} text="Create Options & Variants" icon="fa fa-cogs" />
                        <LinksProduct url={`/products/${props.match.params.key}/edit/delete-options`} text="Delete Options & Variants" icon="fa fa-trash" />
                        <LinksProduct url={`/products/${props.match.params.key}/edit/complex-rules`} text="Complex Rules" icon="fa fa-filter" />
                        <LinksProduct url={`/products/${props.match.params.key}/edit/active-options`} text="Active Options" icon="fa fa-th-list" />
                        <LinksProduct url={`/products/${props.match.params.key}/edit/active-rules`} text="Active Rules" icon="fa fa-th-list" />
                        {/* <LinksProduct url={`/products/${props.match.params.key}/edit/mix-outfit`} text="Combine Outfit" icon="fa fa-th-list" /> */}
                        {/* <button onClick={handleOptions} className="border bg-blue-300 text-white rounde p-3 mx-5">Create Variations</button> */}
                        {/* <LinksProduct url={`/products/${props.match.params.key}/edit/variations`} text="Variations" icon="fa fa-tag" /> */}
                    </ul>
                    <div className="w-11/12 mx-auto lg:w-9/12 mt-10">
                        <Switch>
                            <Route
                                exact
                                path="/products/:key/edit"
                                component={ProductEdit}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/variations"
                                component={VariationMain}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/options"
                                component={ProductOptions}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/delete-options"
                                component={DeleteOptions}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/complex-rules"
                                component={ComplexRules}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/active-options"
                                component={ActiveOptions}
                            />
                            <Route
                                exact
                                path="/products/:key/edit/active-rules"
                                component={ActiveRules}
                            />
                            {/* <Route
                                exact
                                path="/products/:key/edit/mix-outfit"
                                component={MixOutfitBuilder}
                            /> */}
                        </Switch>
                    </div>
                </div>
            </Fragment>
        </BrowserRouter>
    );
};

export default ProductScreen;
