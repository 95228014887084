import api from "../../api"

export const getData = (data) => async dispatch => {
    dispatch({ 
        type: 'SUPPORT_BUTTON'
    })
    try {
        const response = await api.post('/support', data)
        if (response.status === 200) {

            dispatch({ 
                type: 'SUPPORT_SUCCESS',
                payload: "Your information has been sent correctly"
            })

        }
    }
    catch (error) {
        dispatch({
            type: 'SUPPORT_ERROR',
            payload: error.response.data.errors
        })
    }

}