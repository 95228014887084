const initialState = {
    catBtn: false,
    catLoad: false,
    categories: [],
    catGet: false,
    catGetServer: false,
    catErrStatus: false,
    catErrors: {},
    catSuccess: false,
    category: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'CAT_BTN': {
            return {
                ...state,
                catBtn: true
            }
        }
        case 'CAT_BTN_OFF': {
            return {
                ...state,
                catBtn: false
            }
        }
        case 'CAT_LOAD': {
            return {
                ...state,
                catLoad: true
            }
        }

        case 'CAT_RESET_ALERTS': {
            return {
                ...state,
                catBtn: false,
                catSuccess: false,
                catErrStatus: false,
            }
        }
        case 'CAT_REFRESH_DATA': {
            return {
                ...state,
                catGet: false
            }
        }
        case 'CAT_GET_ALL': {
            return {
                ...state,
                catGet: true,
                catLoad: false,
                categories: action.payload
            }
        }
        case 'CAT_ERR': {
            return {
                ...state,
                catErrStatus: true,
                catErrors: action.payload,
                catBtn: false,
                catSuccess: false
            }
        }

        case 'CAT_ERR_CLOSE': {
            return {
                ...state,
                catErrStatus: false
            }
        }

        case 'CAT_SUC_CLOSE': {
            return {
                ...state,
                catSuccess: false
            }
        }

        case 'CAT_ADD': {
            return {
                ...state,
                catBtn: false,
                catSuccess: true,
                catErrStatus: false,
                categories: state.categories.concat(action.payload)
            }
        }
        case 'CAT_EDIT': {
            return {
                ...state,
                catSuccess: true,
                catErrStatus: false,
                catBtn: false,
                categories: {
                    ...state.categories,
                    [action.payload.key]: Object.assign({}, state.categories[action.payload.key], action.payload.values)
                } 
            }
        }
        case 'CAT_REMOVE': {
            var arreglo = state.categories.filter( item => item.id !== action.payload );
            return {
                ...state,
                catSuccess: true,
                catErrStatus: false,
                catBtn: false,
                categories: arreglo
            }
        }
        case 'CAT_FROM_SERVER': {
            return {
                ...state,
                catLoad: false,
                category: action.payload
            }
        }
        default: return state;
    }
}