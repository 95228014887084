import api from "../../api";
import { authURL, grant_type, client_id, client_secret, scope } from '../../functions'

export const loginAction = (data) => async (dispatch) => {
    dispatch({ type: "LOGIN_BTN" });
    try {
        const response = await api.post(`${authURL}/token`, data);
        if (response.status === 200) {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("expires_in", response.data.expires_in);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            localStorage.setItem("token_type", response.data.token_type);
            dispatch({ type: "LOGGED_IN" });
        }
    } catch (error) {
        dispatch({ type: "LOGIN_BTN_OFF" });
        return console.log(error);
    }
};

export const loginOutAction = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_type");
    window.location.reload();
};

export const loginfromBC = (data) => async (dispatch) => {
    try {
        const response = await api.post("/login-from-bc", data);
        //console.log(response);
        if (response.status === 200) {
            const data2 = {
                grant_type: grant_type,
                client_id: client_id,
                client_secret: client_secret,
                username: response.data.user,
                password: response.data.plain,
                scope: scope
            }
            //console.log("data2", data2)
            const response2 = await api.post(`${authURL}/token`, data2);
            //console.log(response2)
            if (response2.status === 200) {
                localStorage.setItem(
                    "access_token",
                    response2.data.access_token
                );
                localStorage.setItem("expires_in", response2.data.expires_in);
                localStorage.setItem(
                    "refresh_token",
                    response2.data.refresh_token
                );
                localStorage.setItem("token_type", response2.data.token_type);
                dispatch({ type: "LOGGED_IN" });
            }
        }
    } catch (error) {
        console.log(error);
    }
};
