const initialState = {
    button: false,
    loading: false,
    rules: [],
    error: false,
    errors: [],
    success: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'COMPLEX_RULES_BUTTON': {
            return {
                ...state,
                button: true
            }
        }
        case 'COMPLEX_RULES_BUTTON_OFF': {
            return {
                ...state,
                button: false
            }
        }
        case 'COMPLEX_RULES_LOADING': {
            return {
                ...state,
                loading: true
            }
        }
        case 'SAVE_COMPLEX_RULES': {
            return {
                ...state,
                button: false,
                error: false,
                success: true,
            }
        }
        case 'COMPLEX_RULES_GET_RULES': {
            return {
                ...state,
                loading: false,
                rules: action.payload
            }
        }
        case 'COMPLEX_RULES_DELETE_ENTRIES': {
            return {
                loading: false,
                rules: []
            }
        }
        case 'COMPLEX_ERROR': {
            return {
                ...state,
                button: false,
                error: true,
                errors: action.payload
            }
        }
        case 'COMPLEX_ERROR_OFF': {
            return {
                ...state,
                error: false,
                errors: [],
            }
        }
        case 'COMPLEX_SUCCESS_OFF': {
            return {
                ...state,
                success: false,
            }
        }
        default: return state;
    }
}