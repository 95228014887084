import React, { Fragment } from "react";
import { useDispatch } from 'react-redux'

const Error = ( { status, errors, close} ) => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            {status &&
            <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-5 animate__animated animate__fadeIn animate__faster"
                role="alert"
            >
                <i className="fas fa-times float-right cursor-pointer" onClick={ () => dispatch({type: close})}></i>
                <ul>
                    {Object.entries(errors).map( ([key,item]) => 
                        <li key={key}>
                            {item}
                        </li>
                    )}
                </ul>
            </div>
            }
        </Fragment>
    );
};

export default Error;
