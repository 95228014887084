const initialState = {
   statusBtn: false,
   errors: {},
   statusError: false,
   success: false,
   menu: {},
   property: {},
   selector: {},
};

export default (state = initialState, action) => {
   switch (action.type) {
      case "BTN_LOAD": {
         return {
            ...state,
            statusBtn: true,
         };
      }
      case "CLOSE_ERROR": {
         return {
            ...state,
            statusError: false,
         };
      }
      case "QUERY_DONE": {
         return {
            ...state,
            statusBtn: false,
            errors: {},
            statusError: false,
            success: true,
         };
      }
      case "QUERY_ERROR": {
         return {
            ...state,
            statusBtn: false,
            statusError: true,
            errors: action.payload,
            success: false,
         };
      }
      case "RESTART_SUCCESS": {
         return {
            ...state,
            success: false,
            statusError: false,
         };
      }
      case 'GET_MENU': {
         return {
            ...state,
            menu: action.payload.menu
         }
      }
      case 'BTN_LOAD_OFF': {
         return {
            ...state,
            success: false,
            statusError: false,
            statusBtn: false,
         }
      }
      case 'GET_PROPERTY': {
         return {
            ...state,
            property: action.payload
         }
      }
      case 'GET_SELECTOR': {
         return {
            ...state,
            selector: action.payload
         }
      }
      default:
         return state;
   }
};
