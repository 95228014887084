import React from 'react';
import Menu from './Menu'
import { verifyLogin } from '../../functions'
import { Redirect } from 'react-router-dom';

const options = [
    {
        url: "/hlogin",
        name: "Home",
        icon: "fa fa-home",
    },
    {
        url: "/login",
        name: "Login",
        icon: "fa fa-sign-in-alt",
    },
    {
        url: "/register",
        name: "Register",
        icon: "fa fa-user",
    },
    {
        url: "/support",
        name: "Support",
        icon: "fa fa-phone",
    },
];

const LoginContent = (props) => {
    return (
        <div className="animate__animated animate__fadeIn animate__faster">
            {verifyLogin() && <Redirect to="/home" />}
            <Menu options={options} />
            {props.children}
        </div>
    );
}

export default LoginContent;