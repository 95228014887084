import React from 'react';

const Breadcrumbs = ( { text } ) => {
    return (
        <div className="bg-gray-900 p-2 text-gray-500">
            {text}
        </div>
    )
}

export default Breadcrumbs;