import React, { useEffect, useState, useRef } from "react";
import Submit from "../components/Submit";
import Error from "../components/Error";
import Success from "../components/Success";
import { useDispatch, useSelector } from "react-redux";
import { TaxoValueStoreAction } from "../../redux/actions/taxonomyValueA";
import TaxoValList from './TaxoValList'

const TaxoOptions = ({ taxonomyId }) => {
    const [data, setData] = useState({
        taxonomy_id: taxonomyId,
        is_default: false,
        label: "",
        value_data: ""
    });
    const defaultRef = useRef();
    const dispatch = useDispatch();
    const taxoval = useSelector((state) => state.taxonomyValueR);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeRadio = (event) => {
        setData({
            ...data,
            is_default: !data.is_default,
        });
    }

    useEffect( () => {
        if(taxoval.taxovalSuccess) {
            defaultRef.current.checked = false
            setData({
                taxonomy_id: taxonomyId,
                is_default: false,
                label: "",
                value_data: ""
            })
        }
    },[taxoval.taxovalSuccess, taxonomyId])

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(TaxoValueStoreAction(data));
    };

    return (
        <div className="w-full lg:w-7/12 m-auto mt-10">
            <h1 className="h1">Add, Edit & Remove Options</h1>
            <Error
                status={taxoval.taxovalErrStatus}
                errors={taxoval.taxovalErrors}
                close="TAXOVAL_ERR_CLOSE"
            />
            <Success
                status={taxoval.taxovalSuccess}
                message="Option value added"
                close="TAXOVAL_SUC_CLOSE"
            />
            <form
                action=""
                className="w-11/12 m-auto lg:flex lg:justify-between lg:items-center my-10"
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col">
                    <label htmlFor="" className="label">
                        Label
                    </label>
                    <input
                        type="text"
                        className="input"
                        name="label"
                        onChange={handleChange}
                        value={data.label}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="" className="label">
                        Value
                    </label>
                    <input
                        type="text"
                        className="input"
                        name="value_data"
                        onChange={handleChange}
                        value={data.value_data}
                    />
                </div>
                <div className="flex flex-col lg:text-center">
                    <label htmlFor="" className="label">
                        Default?
                    </label>
                    <input
                        type="checkbox"
                        className="lg:self-center h-6 w-6"
                        name="is_default"
                        value={data.is_default}
                        onChange={handleChangeRadio}
                        ref={defaultRef}
                    />
                </div>
                <div className="self-end">
                    <Submit
                        status={taxoval.taxovalBtn}
                        text="Add"
                        icon="fa fa-check"
                    />
                </div>
            </form>
            <TaxoValList taxonomyId={taxonomyId} />
        </div>
    );
};

export default TaxoOptions;
