import api from '../../api'

export const selectAttr = (data) => dispatch => {
    dispatch({ type: 'VARIATION_BTN' })
    dispatch({ type: 'VARIATION_BTN_OFF' })
    console.log(data)
}

export const createVariations = (data) => async dispatch => {
    try {
        await api.post('/variation', data)
    } catch (error) {
        console.log(error)
    }
}

export const createOptions =  (product_id) => async dispatch => {
    const response = await api.get(`/create-options/${product_id}`);
    console.log(response)
}