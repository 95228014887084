const initialState = {
    userBtn: false,
    userErrors: {},
    userErrorState: false,
    userSuccessState: false,
    userLoad: false,
    userData: {},
    userGet: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'USER_BTN': {
            return {
                ...state,
                userBtn: true
            }
        }
        case 'USER_BTN_OFF': {
            return {
                ...state,
                userBtn: false
            }
        }
        case 'USER_GET_OFF': {
            return {
                ...state,
                userGet: false
            }
        }
        case 'USER_ERROR': {
            return {
                ...state,
                userErrors: action.payload,
                userErrorState: true,
                userBtn: false,
                userSuccessState: false
            }
        }
        case 'USER_GET': {
            return {
                ...state,
                userLoad: false,
                userErrorState: false,
                userData: action.payload,
                userGet: true,
                userSuccessState: false
            }
        }
        case 'USER_LOAD': {
            return {
                ...state,
                userLoad: true,
                userErrorState: false,
                userSuccessState: false
            }
        }
        case 'USER_LOAD_OFF': {
            return {
                ...state,
                userLoad: false,
            }
        }
        case 'USER_UPDATE': {
            Object.assign({}, state, { userData: action.payload } )
            return {
                ...state,
                userErrorState: false,
                userBtn: false,
                userSuccessState: true
            }
        }
        case 'USER_ERROR_CLOSE': {
            return {
                ...state,
                userErrorState: false
            }
        }
        case 'USER_SUCCESS_CLOSE': {
            return {
                ...state,
                userSuccessState: false
            }
        }
        default: return state;
    }
}