import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userData, updateProfile } from "../../redux/actions/userA";
import Loading from "../components/Loading";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Error from "../components/Error";
import Success from "../components/Success";

const Profile = () => {
    const [changePassword, setChangePassword] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userR);
    const [data, setData] = useState({
        name: "",
        email: "",
        id: "",
        change: false,
    });

    useEffect(() => {
        if (!user.userGet) {
            dispatch(userData());
        }
    }, [dispatch, user.userGet]);

    useEffect(() => {
        if (Object.entries(user.userData).length > 0) {
            setData({
              name: user.userData.name || "",
              email: user.userData.email,
              id: user.userData.id,
              change: false,
            });
        }
    }, [user.userData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateProfile(data));
        // console.log(data)
    };

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePassword = (event) => {
        setChangePassword(!changePassword);
        setData({
            ...data,
            change: !changePassword,
        });
    };

    return (
        <div className="">
            <Breadcrumbs text="Profile" />
            <h1 className="text-center font-bold mt-5 text-lg">User Profile</h1>
            <div className=" w-full lg:w-4/12 m-auto">
                <Error
                    status={user.userErrorState}
                    errors={user.userErrors}
                    close="USER_ERROR_CLOSE"
                />
                <Success
                    status={user.userSuccessState}
                    message="Completed!"
                    close="USER_SUCCESS_CLOSE"
                />
            </div>
            {user.userLoad ? (
                <Loading />
            ) : (
                <form
                    action=""
                    onSubmit={handleSubmit}
                    className="bg-gray-200 w-11/12 lg:w-4/12 rounded m-auto p-5 mt-10 animate__animated animate__fadeIn"
                >
                    <div>
                        <label
                            className="text-gray-600 font-bold"
                            htmlFor="name"
                        >
                            Name
                        </label>
                        <div>
                            <input
                                className="input w-full"
                                type="text"
                                name="name"
                                value={data.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <label
                            className="text-gray-600 font-bold"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <div>
                            <input
                                className="input w-full"
                                type="text"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <label className="text-gray-600 font-bold">
                            <input
                                className="mr-2 leading-tight"
                                type="checkbox"
                                onChange={handleChangePassword}
                                name="change_password"
                                value={changePassword}
                            />
                            <span className="text-sm">Change Password</span>
                        </label>
                    </div>
                    {changePassword && (
                        <Fragment>
                            <div className="mt-5">
                                <label
                                    className="text-gray-600 font-bold"
                                    htmlFor="current_password"
                                >
                                    Current password
                                </label>
                                <div>
                                    <input
                                        className="input w-full"
                                        type="password"
                                        name="current_password"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <label
                                    className="text-gray-600 font-bold"
                                    htmlFor="password"
                                >
                                    New password
                                </label>
                                <div>
                                    <input
                                        className="input w-full"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <label
                                    className="text-gray-600 font-bold"
                                    htmlFor="repeat_password"
                                >
                                    Repeat password
                                </label>
                                <div>
                                    <input
                                        className="input w-full"
                                        type="password"
                                        name="repeat_password"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}
                    <div className="flex justify-center mt-5">
                        <Submit
                            status={user.userBtn}
                            text="Save Changes"
                            icon="fa fa-save"
                        />
                    </div>
                </form>
            )}
        </div>
    );
};

export default Profile;
