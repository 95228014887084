const initialState = {
    variationBtn: false,
    variationLoad: false,
    variationErr: false,
    variationSucc: false,
    variationMsg: [],
    variations: [],
}

export default ( state = initialState, action) => {
    switch(action.type) {
        case 'VARIATION_BTN': {
            return {
                ...state,
                variationBtn: true
            }
        }
        case 'VARIATION_BTN_OFF': {
            return {
                ...state,
                variationBtn: false
            }
        }
        case 'VARIATION_LOAD': {
            return {
                ...state,
                variationLoad: true,
                variationBtn: false,
                variationErr: false,
                variationSucc: false
            }
        }
        case 'VARIATION_ERR': {
            return {
                ...state,
                variationErr: true,
                variationSucc: false,
                variationMsg: action.payload
            }
        }
        case 'VARIATION_ERR_OFF': {
            return {
                ...state,
                variationErr: false,
                variationMsg: []
            }
        }
        case 'VARIATION_SUCC': {
            return {
                ...state,
                variationErr: false,
                variationSucc: true,
                variationMsg: action.payload
            }
        }
        case 'VARIATION_SUCC_OFF': {
            return {
                ...state,
                variationSucc: false,
                variationMsg: []
            }
        }
        default: return state;
    }
}