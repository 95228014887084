import React, { Fragment, useEffect } from "react";
import AddBtn from "../components/AddBtn";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomies, } from "../../redux/actions/taxonomyA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";
import { Link } from "react-router-dom";

const TaxonomyList = () => {
    const taxos = useSelector((state) => state.taxonomyR);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!taxos.taxoGet) {
            dispatch(getTaxonomies());
        }
        dispatch({ type: "RESET_ALERTS" });
    }, [dispatch, taxos.taxoGet]);

    return (
        <div className="">
            <Breadcrumbs text="Taxonomies List" />
            <div className="w-11/12 m-auto">
                <div className="flex justify-end">
                    <AddBtn
                        text="Create new set"
                        icon="fa fa-plus"
                        url="/taxonomies/create"
                    />
                    <button
                        type="button"
                        onClick={ () => dispatch({type: 'REFRESH_DATA'})}
                        className="bg-gray-300 mx-1 hover:bg-gray-400 text-gray-900 font-bold py-2 px-4 rounded my-5"
                    >
                        <i className="fa fa-sync-alt"></i> Refresh
                    </button>
                </div>
                {taxos.taxoLoad ? (
                    <Loading />
                ) : (
                    <Fragment>
                        {taxos.taxonomies.length === 0 ? (
                            <NoRecords />
                        ) : (
                            <table className="table animate__animated animate__fadeIn animate__faster">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Display Name</th>
                                        <th>Type</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(taxos.taxonomies).map(
                                        ([key, item]) => (
                                            <tr key={key}>
                                                <td>{item.name}</td>
                                                <td>{item.display_name}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    <Link
                                                        to={`/taxonomies/${key}/edit`}
                                                        className="btnEdit"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                    <Link
                                                        to={`/taxonomies/${key}/delete`}
                                                        className="btnDelete"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default TaxonomyList;
