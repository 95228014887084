import React, { Fragment, useEffect, useState } from "react";
import AddBtn from "../components/AddBtn";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/actions/productA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import { getAllFromBC, removeFromApp } from "../../redux/actions/productA";
import { addFromSerever } from "../../redux/actions/productA";
import Error from "../components/Error";
import Submit from "../components/Submit";

const AddFromBC = ({ status, close }) => {
    const server = useSelector((state) => state.productR);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!server.loaded) {
            dispatch(getAllFromBC());
        }
    }, [dispatch, server.loaded]);

    const handleChange = (event) => {
        var value = { product_id: event.target.value };
        dispatch(addFromSerever(value));
    };

    return (
        <Modal
            status={status}
            close={close}
            title="Add product from your BigCommcerce"
        >
            {server.loading ? (
                <Loading />
            ) : (
                <div className="m-auto w-full lg:w-8/12">
                    <Error
                        status={server.productErrStatus}
                        errors={server.productErrors}
                        close="PRODUCT_ERR_CLOSE"
                    />
                    <form action="" className="mt-5">
                        <label htmlFor="" className="label">
                            Select product:
                        </label>
                        <select
                            className="select w-full"
                            name="product_id"
                            id="product_id"
                            onChange={handleChange}
                        >
                            <option value=""></option>
                            {Object.values(server.all_data).map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </form>
                </div>
            )}
        </Modal>
    );
};

const RemoveFromApp = ({ status, close, data }) => {
    const dispatch = useDispatch();
    const prod = useSelector((state) => state.productR);

    useEffect(() => {
        if(status) {

            dispatch({ type: "RESTART_REMOVE_SUCCESS" });
        }
    }, [dispatch, status]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(removeFromApp(data));
    };

    return (
        <Modal status={status} close={close} title="Remove Product from App">
            {prod.removeSuccess ? (
                <Fragment>
                    <div className="m-auto w-full lg:w-8/12 mt-10 bg-green-100 border border-green-300 p-3 text-green-800">
                        <strong>{data.name}</strong> was correctly removed!
                    </div>
                    <form
                        action=""
                        className="flex justify-center mt-5"
                    >
                        <button
                            id="closeButton"
                            onClick={close}
                            className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded mr-2"
                        >
                            Ok, close
                        </button>
                    </form>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="m-auto w-full lg:w-8/12 mt-10 bg-red-100 border border-red-300 p-3 text-red-800">
                        Remove <strong>{data.name}</strong> from app?
                    </div>
                    <form
                        action=""
                        className="flex justify-center mt-5"
                        onSubmit={handleSubmit}
                    >
                        <button
                            id="closeButton"
                            onClick={close}
                            className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded mr-2"
                        >
                            No, cancel
                        </button>
                        <Submit
                            status={prod.productBtn}
                            text="Yes, remove"
                            icon="fa fa-trash"
                        />
                    </form>
                </Fragment>
            )}
        </Modal>
    );
};

const ProductList = () => {
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [infoItem, setInfoItem] = useState({});

    useEffect(() => {
        if (!prod.productGet) {
            dispatch(getProducts());
        }
        dispatch({ type: "PRODUCT_RESET_ALERTS" });
    }, [dispatch, prod.productGet]);

    const handleRemoveFromApp = (event) => {
        let element_id = event.target.getAttribute("data-id");
        let element_name = event.target.getAttribute("data-name");
        setInfoItem({
            id: element_id,
            name: element_name,
        });
        setModal2(true);
    };

    return (
        <div className="">
            <Breadcrumbs text="Products List" />
            <div className="w-11/12 m-auto">
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="bg-gray-300 mx-1 hover:bg-gray-400 text-gray-900 font-bold py-2 px-4 rounded my-5"
                    >
                        <i className="fa fa-plus"></i> Add from BigCommerce
                    </button>
                    <AddBtn
                        text="Create new product"
                        icon="fa fa-plus"
                        url="/products/create"
                    />
                    <button
                        type="button"
                        onClick={() =>
                            dispatch({ type: "PRODUCT_REFRESH_DATA" })
                        }
                        className="bg-gray-300 mx-1 hover:bg-gray-400 text-gray-900 font-bold py-2 px-4 rounded my-5"
                    >
                        <i className="fa fa-sync-alt"></i> Refresh
                    </button>
                </div>
                {prod.productLoad ? (
                    <Loading />
                ) : (
                    <Fragment>
                        {prod.products.length === 0 ? (
                            <NoRecords />
                        ) : (
                            <table className="table animate__animated animate__fadeIn animate__faster">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>SKU</th>
                                        <th>Category</th>
                                        <th>Created at</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(prod.products).map(
                                        ([key, item]) => (
                                            <tr key={key}>
                                                <td>{item.name}</td>
                                                <td>{item.sku}</td>
                                                <td>{item.catname}</td>
                                                <td>{item.created_at}</td>
                                                <td className="flex justify-end">
                                                    <Link
                                                        to={`/products/${key}/edit`}
                                                        className="btnEdit"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                    <Link
                                                        to={`/products/${key}/delete`}
                                                        className="btnDelete"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                    <button
                                                        data-id={item.id}
                                                        data-name={item.name}
                                                        className="text-blue-500 hover:text-blue-700 hover:underline"
                                                        onClick={
                                                            handleRemoveFromApp
                                                        }
                                                    >
                                                        <i className="fa fa-times"></i>{" "}
                                                        Remove from App
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Fragment>
                )}
            </div>
            <AddFromBC status={modal} close={() => setModal(false)} />
            <RemoveFromApp
                status={modal2}
                close={() => setModal2(false)}
                data={infoItem}
            />
        </div>
    );
};

export default ProductList;
