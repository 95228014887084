const initialState = {
    records: [],
    status: false,
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'LICENSE_GET': {
            return {
                ...state,
                records: action.payload.data,
                status: action.payload.status,
                message: action.payload.message
            }
        }
        case 'GET_PAYMENT_PLANS': {
            return {
                ...state,
            }
        }
        default: return state;
    }
}