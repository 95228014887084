import React from "react";

const NoRecords = ({ message }) => {
    const messageText = message || "There no records.";
    return (
        <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
            role="alert"
        >
            <p className="font-bold">Info Message</p>
            <p>{messageText}</p>
        </div>
    );
};

export default NoRecords;
