import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, catDelete } from "../../redux/actions/categoryA";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import CancelBtn from "../components/CancelBtn";
import Loading from "../components/Loading";
import Alert from "../components/Alert";
import { Redirect } from 'react-router-dom'

const CatDelete = (props) => {
    const catkey = props.match.params.catkey;
    const cate = useSelector((state) => state.categoryR);
    const [data, setData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cate.catGet) {
            dispatch(getCategories());
        }
    }, [dispatch, cate.catGet]);

    useEffect(() => {
        if (cate.categories.length > 0) {
            setData(cate.categories[catkey]);
        }
    }, [dispatch, cate.categories, catkey]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(catDelete(data.id));
    };

    return (
        <div className="">
            {cate.catSuccess && <Redirect to="/categories" />}
            <Breadcrumbs text="Categories / Delete" />
            <h1 className="h1 lg:w-8/12 mt-10">Delete categorie</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Alert title="Alert" message="This action cannot be reverted." />
                <Error
                    status={cate.catErrStatus}
                    errors={cate.catErrors}
                    close="CAT_ERR_CLOSE"
                />
                <Success
                    status={cate.catSuccess}
                    message="Operation completed successfully."
                    close="CAT_SUC_CLOSE"
                />
            </div>
            {!data.name ? (
                <Loading />
            ) : (
                <Fragment>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="form lg:w-4/12 animate__animated animate__fadeIn animate__faster"
                        autoComplete="off"
                    >
                        <div className="flex flex-col">
                            <label htmlFor="" className="mr-2 w-full label">
                                Name:
                            </label>
                            <input
                                type="text"
                                className="input w-full opacity-50"
                                value={data.name}
                                disabled
                            />
                        </div>
                        <div className="flex justify-center mt-5">
                            <CancelBtn
                                text="Cancel"
                                icon="fa fa-arrow-left"
                                url="/categories"
                            />
                            <Submit
                                status={cate.catBtn}
                                text="Yes, Delete"
                                icon="fa fa-trash"
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

export default CatDelete;
