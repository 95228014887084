import api from "../../api";

export const createOptions = (data) => async (dispatch) => {
    let total = 0
    let sum = 0;
    data.ids.map( id => { 
        // eslint-disable-next-line
        total = Object.values(data.selected).filter( sel => sel['taxo_id'] == id)
        if(total.length  > 0 ) {
            sum = sum+1;
        } 
    })

    if (sum >= 2) {
        dispatch({
            type: "PRODUCT_OPTION_BUTTON",
        });
        try {
            // const response = await api.post('/product-options', data);
            const response = await api.post("/product-options", data);
            console.log(response);
            if (response.status === 200) {
                dispatch({
                    type: "PRODUCT_OPTION_SUCCESS",
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: "PRODUCT_OPTION_ERROR",
                payload: error.response.data.errors,
            });
        }
    } else {
        dispatch({
            type: "PRODUCT_OPTION_ERROR",
            payload: ['You should select at least one element from each option'],
        });
    }
};

export const getOptions = (product_id) => async (dispatch) => {
    dispatch({
        type: "PRODUCT_OPTION_LOADING",
    });
    try {
        const response = await api.get(`/product-options/${product_id}/edit`);
        if(response.status === 200) {
            dispatch({
                type: 'PRODUCT_OPTIONS',
                payload: response.data
            })
        }
    } catch (error) {
        console.log(error);
    }
};


export const deleteOptions = (data) => async dispatch => {
    dispatch({ type: 'PRODUCT_OPTION_BUTTON' })
    try {
        const response = await api.post('/product-options/delete-all', data)
        if(response.status===200) {
            dispatch({ type: 'PRODUCT_OPTION_DELETE' }) 
        }
    } catch(error) {
        console.log(error)
    }
}

export const getVariants = (product_id) => async dispatch => {
    dispatch( { type: 'PRODUCT_OPTION_LOADING' } )
    try {
        const response = await api.get(`/product-options/${product_id}/get-variants`);
        if( response.status === 200) {
            dispatch({
                type: 'PRODUCT_OPTIONS_GET_VARIANTS',
                payload: response.data.data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}