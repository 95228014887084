import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRules } from "../../redux/actions/complexRulesA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";
import Modal from "../components/Modal";
import { deleteAllEntries } from '../../redux/actions/complexRulesA'

const ConfirmDelete = ({ status, close, product }) => {
    const dispatch = useDispatch();
    const handleClickButton = () => {
        dispatch(deleteAllEntries(product));
        close();
    }

    return (
        <Modal status={status} close={close} title="Delete all entries">
            <div className="mt-5">
                This action is gonna delete all entries for this product and it
                cannot be reverted. Are you sure?
            </div>
            <div className="flex justify-center mt-5">
                <button type="button" onClick={close} className="rounded bg-gray-200 border border-gray-300 text-gray-800 p-2 mr-2">
                    <i className="fa fa-times"></i> Cancel
                </button>
                <button type="button" onClick={handleClickButton} className="rounded bg-red-300 border border-red-400 p-2 text-red-900">
                    <i className="fa fa-check"></i> Yes, continue
                </button>
            </div>
        </Modal>
    );
};

const ActiveRules = () => {
    const rules = useSelector((state) => state.complexRulesR);
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (prod.product.id) {
            dispatch(getRules(prod.product.id));
        }
        dispatch({ type: 'COMPLEX_SUCCESS_OFF'})
    }, [dispatch, prod.product.id]);

    return (
        <div className="w-11/12 m-auto">
            <h1 className="h1">Active Rules</h1>
            <h2 className="text-lg underline font-bold mt-5">
                {prod.product.name}
            </h2>
            {rules.loading && <Loading />}
            {!rules.loading && prod.product.id && (
                <div className="animate__animated animate__fadeIn animate__faster">
                    <div className="flex justify-end p-3">
                        <button
                            onClick={() => setModalOpen(true)}
                            className="rounded bg-gray-200 border border-gray-400 p-2 hover:bg-gray-300 text-gray-700"
                        >
                            Clear all entries & start over
                        </button>
                    </div>
                    {rules.rules.length === 0 && (
                        <div className="mt-5">
                            <NoRecords message="No variants / sku's availables" />
                        </div>
                    )}
                    {rules.rules.length > 0 && (
                        <table className="table mt-5">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Price Adjuster</th>
                                    <th>Id</th>
                                    <th>Enabled</th>
                                    <th>Stop</th>
                                    <th>...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(rules.rules).map((item) => (
                                    <tr key={item.id}>
                                        <td>
                                            {item.image_url ? (
                                                <img
                                                    src={item.image_url}
                                                    alt=""
                                                    className="w-10"
                                                />
                                            ) : (
                                                "--"
                                            )}
                                        </td>
                                        <td>
                                            {item.price_adjuster !== null
                                                ? "$" +
                                                  item.price_adjuster
                                                      .adjuster_value
                                                : "--"}
                                        </td>
                                        <td>{item.id}</td>
                                        <td>{item.enabled ? "Yes" : "No"}</td>
                                        <td>{item.stop ? "Yes" : "No"}</td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
            <ConfirmDelete
                status={modalOpen}
                close={() => setModalOpen(false)}
                product={prod.product.id}
            />
        </div>
    );
};

export default ActiveRules;
