const initialState = {
    loginbtn: false,
    loggedIn: false,
    loggedOut: false,
    dataUser: {}
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'LOGIN_BTN': {
            return {
                ...state,
                loginbtn: true
            }
        }
        case 'LOGIN_BTN_OFF': {
            return {
                ...state,
                loginbtn: false
            }
        }
        case 'LOGGED_IN': {
            return {
                ...state,
                loginbtn: false,
                loggedIn: true,
            }
        }
        case 'LOGGED_OUT': {
            return {
                ...state,
                loggedOut: true
            }
        }
        default: return state;
    }
}