const initialState = {
    taxovalBtn: false,
    taxovalLoad: false,
    taxonomyValues: [],
    taxonomyValue: {},
    taxovalGet: false,
    taxovalErrStatus: false,
    taxovalErrors: {},
    taxovalSuccess: false,
    taxovalSuccess_2: false,
    taxovalModal: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'TAXOVAL_BTN': {
            return {
                ...state,
                taxovalBtn: true
            }
        }
        case 'TAXOVAL_BTN_OFF': {
            return {
                ...state,
                taxovalBtn: false
            }
        }
        case 'TAXOVAL_LOAD': {
            return {
                ...state,
                taxovalLoad: true
            }
        }
        case 'RESTART_TAXOVAL': {
            return {
                ...state,
                taxovalBtn: false,
                taxovalErrStatus: false,
                taxovalSuccess: false,
            }
        }
        case 'TAXOVAL_GET_ALL': {
            return {
                ...state,
                taxovalGet: true,
                taxovalLoad: false,
                taxonomyValues: action.payload
            }
        }
        case 'TAXOVAL_ERR': {
            return {
                ...state,
                taxovalErrStatus: true,
                taxovalErrors: action.payload,
                taxovalBtn: false,
                taxovalSuccess: false,
                taxovalSuccess_2: false
            }
        }

        case 'TAXOVAL_ERR_CLOSE': {
            return {
                ...state,
                taxovalErrStatus: false
            }
        }

        case 'TAXOVAL_SUC_CLOSE': {
            return {
                ...state,
                taxovalSuccess: false
            }
        }
        case 'TAXOVAL_ADD': {
            return {
                ...state,
                taxovalBtn: false,
                taxovalSuccess: true,
                taxovalErrStatus: false,
                // taxonomyValues: state.taxonomyValues.concat(action.payload)
                taxovalGet: false,
            }
        }
        case 'TAXOVAL_EDIT': {
           return {
                ...state,
                taxovalSuccess: true,
                taxovalErrStatus: false,
                taxovalvalBtn: false,
                taxovalGet: false,
                // taxonomyValues:  {
                //     ...state.taxonomyValues,
                //     [action.payload.key] : Object.assign({}, state.taxonomyValues[action.payload.key], action.payload.values)
                // }
            }
        }
        case 'TAXOVAL_REMOVE': {
            // var arreglo = state.taxonomyValues.filter( item => item.id !== action.payload );
            return {
                ...state,
                taxovalSuccess: false,
                taxovalSuccess_2: true,
                taxovalErrStatus: false,
                taxovalBtn: false,
                // taxonomyValues: arreglo,
                taxovalGet: false,
            }
        }
        case 'TAXOVAL_MODAL': {
            // eslint-disable-next-line
            var arreglo_modal = state.taxonomyValues.filter( (item) => item.id == action.payload );
            return {
                ...state,
                taxonomyValue: arreglo_modal[0],
                taxovalModal: true
            }
        }
        case 'TAXOVAL_MODAL_OFF': {
            return {
                ...state,
                taxovalModal: false,
                taxovalSuccess: false,
                taxovalSuccess_2: false,
                taxovalErrStatus: false,
                taxovalBtn: false,
            }
        }
        default: return state;
    }
}