import React, { useEffect } from "react";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import { TaxoValDeleteAction } from "../../redux/actions/taxonomyValueA";
import Submit from "../components/Submit";
import { useSelector, useDispatch } from 'react-redux'

const TaxoValDelete = () => {
    const taxoval = useSelector((state) => state.taxonomyValueR);
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch({type:'RESTART_TAXOVAL'})
    },[dispatch])

    const handleDelete = (event) => {
        event.preventDefault()
        dispatch(TaxoValDeleteAction(taxoval.taxonomyValue.id));
    };

    useEffect( () => {
        if(taxoval.taxovalSuccess_2) {
            dispatch( {type:'TAXOVAL_MODAL_OFF'} )
        }
    },[dispatch, taxoval.taxovalSuccess_2])

    return (
        <Modal title="Delete Option Values" status={taxoval.taxovalModal} close={ () => dispatch( {type:'TAXOVAL_MODAL_OFF'} )}>
            <Alert
                title="Take care"
                message="This operation cannot be reverted"
            />
            <div className="text-lg text-center mt-10">
                Are you sure to delete <strong>{taxoval.taxonomyValue.label}</strong> with value{" "}
                <strong>{taxoval.taxonomyValue.value_data}</strong>?
            </div>
            <form action="" onSubmit={handleDelete} className="flex justify-center mt-5">
                <Submit
                    status={taxoval.taxovalBtn}
                    text="Yes, Delete"
                    icon="fa fa-trash"
                />
            </form>
        </Modal>
    );
};

export default TaxoValDelete;
