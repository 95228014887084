import React from "react";
import { verifyLogin } from "../../functions";
import { Redirect } from "react-router-dom";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import MakePayment from "../subscription/MakePayment";
import { getStatusApp } from "../../redux/actions/paymentActions";

const options = [
    {
        url: "/home",
        name: "Home",
        icon: "fa fa-home",
    },
    {
        url: "/categories",
        name: "Categories",
        icon: "fa fa-tag",
    },
    {
        url: "/products",
        name: "Products",
        icon: "fa fa-box",
    },
    {
        url: "/taxonomies",
        name: "Taxonomies",
        icon: "fa fa-list",
    },
    {
        url: "/profile",
        name: "Profile",
        icon: "fa fa-user",
    },
    {
        url: "/subscription",
        name: "Subscription",
        icon: "fa fa-star",
    },
    {
        url: "/support",
        name: "Support",
        icon: "fa fa-phone",
    },
    {
        url: "/logout",
        name: "Log out",
        icon: "fa fa-sign-out-alt",
    },
];

const Content = (props) => {
    const dispatch = useDispatch();
    const payment = useSelector((state) => state.paymentReducers);

    React.useEffect(() => {
        dispatch(getStatusApp());
    }, [dispatch]);

    return (
        <div>
            {!verifyLogin() && <Redirect to="/login" />}
            <Menu options={options} />
            <div>{props.children}</div>
            {payment.verify_finish && !payment.verify.status && <MakePayment />}
        </div>
    );
};

export default Content;
