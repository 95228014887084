import React, { Fragment, useEffect } from "react";
import AddBtn from "../components/AddBtn";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, } from "../../redux/actions/categoryA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";
import { Link } from "react-router-dom";

const CategoryList = () => {
    const cate = useSelector((state) => state.categoryR);
    const dispatch = useDispatch();

    useEffect(() => {
        //if(!cate.catGet) {
            dispatch(getCategories());
        //}
        dispatch({ type: "CAT_RESET_ALERTS" });
    }, [dispatch]);

    // console.log(cate.categories);
    return (
        <div className="">
            <Breadcrumbs text="Categories List" />
            <div className="w-11/12 m-auto">
                <div className="flex justify-end">
                    <AddBtn
                        text="Create new category"
                        icon="fa fa-plus"
                        url="/categories/create"
                    />
                    <button
                        type="button"
                        onClick={ () => dispatch({type: 'CAT_REFRESH_DATA'})}
                        className="bg-gray-300 mx-1 hover:bg-gray-400 text-gray-900 font-bold py-2 px-4 rounded my-5"
                    >
                        <i className="fa fa-sync-alt"></i> Refresh
                    </button>
                </div>
                {cate.catLoad ? (
                    <Loading />
                ) : (
                    <Fragment>
                        {cate.categories.length === 0 ? (
                            <NoRecords />
                        ) : (
                            <table className="table animate__animated animate__fadeIn animate__faster">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Title</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(cate.categories).map(
                                        ([key, item]) => (
                                            <tr key={key}>
                                                <td>{item.name}</td>
                                                <td>{item.page_title}</td>
                                                <td>
                                                    <Link
                                                        to={`/categories/${key}/edit`}
                                                        className="btnEdit"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                    <Link
                                                        to={`/categories/${key}/delete`}
                                                        className="btnDelete"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default CategoryList;
