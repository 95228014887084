const initialState = {
    taxoBtn: false,
    taxoLoad: false,
    taxonomies: [],
    taxoGet: false,
    taxoErrStatus: false,
    taxoErrors: {},
    taxoSuccess: false,
    taxoSelected: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'TAXO_BTN': {
            return {
                ...state,
                taxoBtn: true
            }
        }
        case 'TAXO_BTN_OFF': {
            return {
                ...state,
                taxoBtn: false
            }
        }
        case 'TAXO_LOAD': {
            return {
                ...state,
                taxoLoad: true
            }
        }

        case 'RESET_ALERTS': {
            return {
                ...state,
                taxoBtn: false,
                taxoSuccess: false,
                taxoErrStatus: false,
            }
        }
        case 'REFRESH_DATA': {
            return {
                ...state,
                taxoGet: false
            }
        }
        case 'TAXO_GET_ALL': {
            return {
                ...state,
                taxoGet: true,
                taxoLoad: false,
                taxonomies: action.payload
            }
        }
        case 'TAXO_ERR': {
            return {
                ...state,
                taxoErrStatus: true,
                taxoErrors: action.payload,
                taxoBtn: false,
                taxoSuccess: false
            }
        }

        case 'TAXO_ERR_CLOSE': {
            return {
                ...state,
                taxoErrStatus: false
            }
        }

        case 'TAXO_SUC_CLOSE': {
            return {
                ...state,
                taxoSuccess: false
            }
        }

        case 'TAXO_ADD': {
            return {
                ...state,
                taxoBtn: false,
                taxoSuccess: true,
                taxoErrStatus: false,
                taxonomies: state.taxonomies.concat(action.payload)
            }
        }
        case 'TAXO_EDIT': {
            return {
                ...state,
                taxoSuccess: true,
                taxoErrStatus: false,
                taxoBtn: false,
                taxonomies: {
                    ...state.taxonomies,
                    [action.payload.key]: Object.assign({}, state.taxonomies[action.payload.key], action.payload.values)
                } 
            }
        }
        case 'TAXO_REMOVE': {
            var arreglo = state.taxonomies.filter( item => item.id !== action.payload );
            return {
                ...state,
                taxoSuccess: true,
                taxoErrStatus: false,
                taxoBtn: false,
                taxonomies: arreglo
            }
        }
        
        case 'TAXO_SELECTED': {
            return {
                ...state,
                taxoSelected: action.payload,
                taxoLoad: false
            }
        }
        default: return state;
    }
}