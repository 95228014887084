import React from 'react';
import { Link } from 'react-router-dom';

const CancelBtn = ( {text,icon,url} ) => {
    return (
        <Link to={url} className="bg-red-500 mx-1 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded">
            <i className={icon}></i> {text}
        </Link>
    )
}

export default CancelBtn;