import React, { Fragment } from "react";
import { useDispatch } from 'react-redux'

const Success = ({ status, message, close }) => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            {status && 
            <div
                className="bg-green-100 border-t border-b border-green-500 text-green-700 px-4 py-3  my-5 animate__animated animate__fadeIn animate__faster"
                role="alert"
            >
                <i className="fas fa-times float-right cursor-pointer" onClick={ () => dispatch({type: close})}></i>
                <p className="text-sm">
                    {message}
                </p>
                
            </div>
            }
        </Fragment>
    );
};

export default Success;
