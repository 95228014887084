import React, { Fragment } from "react";

const Submit = ({ status, text, icon }) => {
    return (
        <Fragment>
            {status ? (
                <button
                    type="button"
                    disabled
                    className="bg-blue-500 text-white cursor-not-allowed font-bold py-2 px-4 border-b-4 border-blue-700 rounded opacity-25"
                >
                    <i className="fa fa-spin fa-spinner"></i> Loading...
                </button>
            ) : (
                <button type="submit" className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                    <i className={icon}></i> {text}
                </button>
            )}
        </Fragment>
    );
};

export default Submit;
