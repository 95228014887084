import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomies } from "../../redux/actions/taxonomyA";
import NoRecords from "../components/NoRecords";
import Loading from "../components/Loading";
import DefineOptions from "./DefineOptions";

const ProductOptions = () => {
    const taxo = useSelector((state) => state.taxonomyR);
    const prod = useSelector((state) => state.productR);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        name: [],
        total: 0,
    });
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (!taxo.taxoGet) {
            dispatch(getTaxonomies());
        }
    }, [dispatch, taxo.taxoGet]);

    const handleChange = (event) => {
        let element = event.target;
        let checked = element.checked;
        if (checked === true) {
            setData({
                ...data,
                name: data.name.concat(element.value),
                total: data.total + 1,
            });
        } else {
            // eslint-disable-next-line
            var arreglo = data.name.filter( (item) => item != event.target.value)
            setData({
                ...data,
                name: arreglo,
                total: data.total - 1,
            })
        }
    };

    const handleReset = () => {
        setData({
            name: [],
            total: 0,
        });
        setStep(0);
    };

    const handleSubmit = (event) => {
        setData({
            ...data,
        });
        setStep(2);
    };

    // console.log(data.total);

    return (
        <div>

            <h1 className="h1">Create Product Options</h1>
            <h2 className="w-full lg:w-11/12 font-bold underline m-auto text-lg mt-10">{prod.product.name}</h2>
            {/* eslint-disable-next-line */}
            {step === 0 && (prod.product.length == 0 || taxo.taxoLoad) ? (
                <Loading />
            ) : (
                <Fragment>
                    {/* eslint-disable-next-line */}
                    {taxo.taxonomies.length == 0 ? (
                        <NoRecords message="There is no any option create. Please go to Taxonomies to start" />
                    ) : (
                        <div className="mt-5  lg:w-11/12 m-auto">
                            {/* eslint-disable-next-line */}
                            {!taxo.taxoLoad && step == 0 && (
                                <Fragment>
                                    <h3 className="font-bold mb-1">
                                        Select attributes to configure
                                    </h3>
                                    <table className="table w-full mt-1">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>Display Name</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(
                                                taxo.taxonomies
                                            ).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td className="w-1/12">
                                                        <input
                                                            type="checkbox"
                                                            className="w-5 h-5 block cursor-pointer"
                                                            name={`taxo${value.id}`}
                                                            value={value.id}
                                                            onClick={
                                                                handleChange
                                                            }
                                                            id={`taxo${value.id}`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="block cursor-pointer"
                                                            htmlFor={`taxo${value.id}`}
                                                        >
                                                            {value.name}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="block cursor-pointer"
                                                            htmlFor={`taxo${value.id}`}
                                                        >
                                                            {value.display_name}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="block cursor-pointer"
                                                            htmlFor={`taxo${value.id}`}
                                                        >
                                                            {value.type}
                                                        </label>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="mt-5 flex justify-center">
                                        {data.total > 1 && (
                                            <button
                                                type="button"
                                                onClick={handleSubmit}
                                                className="btnSubmit"
                                            >
                                                <i className="fa fa-arrow-right mr-1"></i>
                                                Submit & Next
                                            </button>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    )}
                </Fragment>
            )}
            {step === 2 && (
                <DefineOptions
                    selected={data.name}
                    resetOption={handleReset}
                    product={prod.product.id}
                />
            )}
        </div>
    );
};
export default ProductOptions;
