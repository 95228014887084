const initialState = {
    selected: {},
    button: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'OB_BUTTON': {
            return {
                ...state,
                button: true
            }
        }
        case 'OB_GET_SELECTED': {
            return {
                ...state,
                selected: action.payload
            }
        }
        case 'OB_SAVE': {
            return {
                ...state,
                button: false
            }
        }
        default: return state;
    }
}