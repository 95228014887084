import React from 'react';
import LoadingDots from '../../assets/img/loading_dots.gif'

const Loading = () => {
    return (
        <div className="animate__animated animate__fadeIn animate__faster flex justify-center">
            <img src={LoadingDots} alt="" className="w-1/4"/>
        </div>
    )
}

export default Loading