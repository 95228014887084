const initialState = {
    button: false,
    loading: false,
    results: [],
    result: [],
    loading_details: false
}

export default (state = initialState, action) =>{
    switch (action.type){
        
        case "button": {
            return {
                ...state, button: true
            }
        }
        case "loading": {
            return {
                ...state, loading: true
            }
        }
        case "results": {
            return {
                ...state, loading: false,
                results: action.payload
            }
        }
        case "result": {
            return {
                ...state, loading: false,
                result: action.payload,
                loading_details: false
            }
        }
        case 'loading_details': {
            return {
                ...state,
                loading_details: true
            }
        }
        default: return state;
    }
}