import React from "react";
import ReactDOM from 'react-dom';
// import { useSelector } from 'react-redux';

const ModalVerify = ( props ) => {
    // const verify = useSelector( state => state.paymentReducers );
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="fixed w-screen h-screen bg-black z-40 top-0 left-0 opacity-50"></div>
            <div className="fixed w-screen h-screen top-0 left-0 z-40 pt-32">
                <div
                    className={`w-11/12 md:w-3/4 lg:w-7/12 mx-auto shadow-lg animate__animated animate__fadeIn animate__faster`}
                >
                    <div className="bg-gray-200 flex justify-between py-1 px-5 font-bold rounded-t-lg">
                        {props.title}
                        {/* <span
                            className="cursor-pointer"
                        >
                            <i className="fa fa-times"></i>
                        </span> */}
                    </div>
                    <div className="bg-white p-5">
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>,
        document.getElementById("modal_verify")
    );
};

export default ModalVerify;
