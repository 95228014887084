import api from "../../api";

export const getInfo = () => async (dispatch) => {
    try {
        const response = await api.get("payment/info-account");
        if (response.status === 200) {
            dispatch({
                type: "GET_INFO",
                payload: response.data[0],
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const getStatusApp = () => async (dispatch) => {
    try {
        const response = await api.get("payment/verify");
        if (response.status === 200) {
            dispatch({
                type: "VERIFY",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const createPayment = (data) => async (dispatch) => {
    
    dispatch({
        type: "BTN_LOAD",
    });
    try {
        const response = await api.post("payment/create-payment", data);
        //console.log(response);
        if (response.status === 200) {
            if (response.data.original.errors) {
                dispatch({
                    type: "QUERY_ERROR",
                    payload: response.data.original.errors,
                });
            } else {
                dispatch({
                    type: "PAYMENT_COMPLETED",
                });
            }
        }
    } catch (error) {
        if(error.response.data.original) {
            dispatch({
                type: "QUERY_ERROR",
                payload: error.response.data.original.errors,
            });
        }
        if(error.response.data.errors) {
            dispatch({
                type: "QUERY_ERROR",
                payload: error.response.data.errors,
            });
        }
    }
};

export const payWithCheck = (data) => async (dispatch) => {
    dispatch({
        type: "BTN_LOAD",
    });
    try {
        const response = await api.post("payment/pay-with-check", data);
        if (response.status === 200) {
            if (response.data.original.errors) {
                dispatch({
                    type: "QUERY_ERROR",
                    payload: response.data.original.errors,
                });
            } else {
                dispatch({
                    type: "PAYMENT_COMPLETED",
                });
            }
        }
    } catch (error) {
        //console.log(error)
        if(error.response.data.original) {
            dispatch({
                type: "QUERY_ERROR",
                payload: error.response.data.original.errors,
            });
        }
        if(error.response.data.errors) {
            dispatch({
                type: "QUERY_ERROR",
                payload: error.response.data.errors,
            });
        }
    }
};

export const getHistory = () => async (dispatch) => {
    try {
        const response = await api.post("payment/get-history", );
        if (response.status === 200) {
            dispatch({
                type: "GET_HISTORY",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const cancelSubscription = () => async dispatch => {
    try {
        const response = await api.post('payment/cancel-subscription');
        if (response.status === 200) {
            dispatch({
                type: 'CANCEL_SUBSCRIPTION'
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export const getPaymentPlans = () => async dispatch => {
    try {
        const response = await api.get('payment-plan');
        //console.log(response);
        if (response.status === 200) {
            dispatch({
                type: 'GET_PAYMENT_PLANS',
                payload: response.data
            })
        }
    } catch (error) {
        console.log(error)
    }
}