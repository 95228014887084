import api from "../../api";

export const getTaxonomies = () => async (dispatch) => {
    dispatch({ type: "TAXO_LOAD" });
    try {
        const response = await api.get("taxonomy");
        if (response.status === 200) {
            dispatch({
                type: "TAXO_GET_ALL",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const TaxoStore = (data) => async (dispatch) => {
    dispatch({ type: "TAXO_BTN" });
    try {
        const response = await api.post("taxonomy", data);
        dispatch({
            type: "TAXO_ADD",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "TAXO_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const TaxoUpdate = (data,taxoKey) => async (dispatch) => {
    dispatch({ type: "TAXO_BTN" });
    try {
        await api.put(`taxonomy/${data.id}`, data);
        dispatch({
            type: "TAXO_EDIT",
            payload: {
                values: data,
                key: taxoKey
            }
        });
    } catch (error) {
        dispatch({
            type: "TAXO_ERR",
            payload: error.response.data.errors,
        });
    }
};


export const TaxoDeleteAction = (data) => async (dispatch) => {
    dispatch({ type: "TAXO_BTN" });
    try {
        await api.delete(`taxonomy/${data}`);
        dispatch({
            type: "TAXO_REMOVE",
            payload: data
        });
    } catch (error) {
        dispatch({
            type: "TAXO_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const getTaxonomiesSelected = (data) => async dispatch => {
    dispatch({
        type: 'TAXO_LOAD'
    })
    try {
        const response = await api.post('taxonomy/selected', data)
        if(response.status === 200) {
            dispatch({
                type: 'TAXO_SELECTED',
                payload: response.data
            })
        }
    } catch (error) {   
        console.log(error)
    }
}

