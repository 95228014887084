import api from "../../api";

export const getCategories = () => async (dispatch) => {
    dispatch({ type: "CAT_LOAD" });
    try {
        const response = await api.get("category");
        if (response.status === 200) {
            dispatch({
                type: "CAT_GET_ALL",
                payload: response.data.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const catStore = (data) => async (dispatch) => {
    dispatch({ type: "CAT_BTN" });
    try {
        const response = await api.post("category", data);
        dispatch({
            type: "CAT_ADD",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "CAT_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const catUpdate = (data,key) => async (dispatch) => {
    dispatch({ type: "CAT_BTN" });
    try {
        await api.put(`category/${data.id}`, data);
        dispatch({
            type: "CAT_EDIT",
            payload: {
                values: data,
                key: key
            }
        });
    } catch (error) {
        dispatch({
            type: "CAT_ERR",
            payload: error.response.data.errors,
        });
    }
};


export const catDelete = (data) => async (dispatch) => {
    dispatch({ type: "CAT_BTN" });
    try {
        await api.delete(`category/${data}`);
        dispatch({
            type: "CAT_REMOVE",
            payload: data
        });
    } catch (error) {
        dispatch({
            type: "CAT_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const getAllFromServer = (bc_category_id) => async dispatch => {
    dispatch( { type: "CAT_LOAD" } );
    try {
        const response = await api.get(`/category/${bc_category_id}/server`)
        if(response.status === 200) {
            dispatch({
                type: "CAT_FROM_SERVER",
                payload: response.data.data
            })
        }
    } catch (error) {
        console.log(error);
    }
}