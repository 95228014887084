import React, { useState } from "react";
import Submit from "../components/Submit";
import { grant_type, client_id, client_secret, scope } from '../../functions'
import { loginAction } from '../../redux/actions/loginA';
import { useSelector, useDispatch } from 'react-redux';

const Login = () => {
    const dispatch = useDispatch();
    const loginData = useSelector( state => state.loginR );


    const [data, setData] = useState({
        grant_type: grant_type,
        client_id: client_id,
        client_secret: client_secret,
        username: "publio@nuethic.com",
        password: "123456",
        scope: scope
    });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(loginAction(data))
    };

    return (
        <div className="animate__animated animate__fadeIn animate__faster">
            {loginData.loggedIn && window.location.reload() }
            <h1 className="h1 mt-10">Login</h1>
            <form
                action=""
                onSubmit={handleSubmit}
                autoComplete="off"
                className="sm:1/2 md:w-4/12 lg:w-3/12 xl:w-3/12 form"
            >
                <div className="flex flex-col">
                    <label htmlFor="" className="text-gray-600 font-bold">
                        Username:
                    </label>
                    <input
                        type="text"
                        name="username"
                        className="input"
                        onChange={handleChange}
                        value={data.username}
                    />
                </div>
                <div className="flex flex-col mt-2">
                    <label htmlFor="" className="text-gray-600 font-bold">
                        Password:
                    </label>
                    <input
                        type="password"
                        name="password"
                        className="input"
                        onChange={handleChange}
                        value={data.password}
                    />
                </div>
                <div className="mt-2 flex justify-end">
                    <Submit
                        status={loginData.loginbtn}
                        text="Log In"
                        icon="fa fa-sign-in-alt"
                    />
                </div>
            </form>
        </div>
    );
};

export default Login;
